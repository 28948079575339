/**
 *  Purpose: the modifications that are carried on the User object for each command
 */

import {initialState} from './index'
import * as types from '../actions/index'

import {estimateDeviceType} from "../utils/deviceUtils"
import {
    MAP_DEFAULT_ZOOM_LEVELS, 
    MAP_SUBREGION_ZOOM_LEVELS, 
    MAP_COUNTRY_ZOOM_LEVELS
} from "../constants/mapBoxConstants"

const systemReducer = (state = initialState.system, action) => {
    const {type, payload} = action;
    switch (type){
        case types.SET_VERSION: {
            return {
                ...state,
                version: initialState.system.version
            }
        }

        case types.TOGGLE_LOADED: {
            return {
                ...state,
                loaded: payload.loaded
            }
        }

        case types.TOGGLE_LOADING: {
            return {
                ...state,
                isLoading: payload.isLoading
            }
        }
        
        case types.SET_DEVICE: {
            const device = estimateDeviceType()
            return {
                ...state,
                device,
                mapZoomLevels: {
                    caribbean: MAP_DEFAULT_ZOOM_LEVELS[device],
                    subregion: MAP_SUBREGION_ZOOM_LEVELS[device],
                    country: MAP_COUNTRY_ZOOM_LEVELS[device]
                }
            }
        }

        default: return state
        
    }
}

export default systemReducer