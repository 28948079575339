/**
 * Purpose: to specify the commands that are allowed on the Country objects in state
 */

export const SAVE_COUNTRIES = 'SAVE_COUNTRIES'
export const SELECT_COUNTRY = 'SELECT_COUNTRY'
export const DESELECT_COUNTRY = 'DESELECT_COUNTRY'
export const DESELECT_ALL_COUNTRIES = 'DESELECT_ALL_COUNTRIES'

export const saveCountries = (countries) => {
    return {
        type: SAVE_COUNTRIES,
        payload: {
            countries
        }
    }
}

export const selectCountry = (countryId, multiSelect = false, lastSelectedId = null) => {
    return {
        type: SELECT_COUNTRY,
        payload: {
            countryId,
            multiSelect,
            lastSelectedId
        }
    }
}

export const deselectCountry = (countryId) => {
    return {
        type: DESELECT_COUNTRY,
        payload: {
            countryId
        }
    }
}

export const deselectAllCountries = () => {
    return {
        type: DESELECT_ALL_COUNTRIES
    }
}