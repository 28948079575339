import React from "react"
import styles from "./MapControls.module.css"
import CountrySearch from "../CountrySearch"
import LayerPicker from "../LayerPicker"
import PropTypes from "prop-types"

function MapControls({multiSelectCountries, onToggleMultiSelectCountries}){
    return (
        <div className={[styles.container, "mapWidget"].join(' ')}>
            <CountrySearch 
            multiSelectCountries={multiSelectCountries}
            onToggleMultiSelectCountries={onToggleMultiSelectCountries}
          />
            <div className={styles.layerPicker}>
                <LayerPicker/>
            </div>
        </div>
    )
}

MapControls.propTypes = {
    multiSelectCountries: PropTypes.bool.isRequired,
    onToggleMultiSelectCountries: PropTypes.func.isRequired
}

export default MapControls;