// export const BIG_SCREEN_MAX_WIDTH = 1920
// export const MED_SCREEN_MAX_WIDTH = 1280
// export const PHONE_SCREEN_WIDTH = 360

export const BIG_SCREEN_MAX_WIDTH = 2500
export const MED_SCREEN_MAX_WIDTH = 1500
export const TABLET_SCREEN_MAX_WIDTH = 900
export const PHONE_SCREEN_MAX_WIDTH = 500 

export const DEVICE_BIG_LAPTOP = "bigLaptop"
export const DEVICE_LAPTOP = "laptop"
export const DEVICE_TABLET = "tablet"
export const DEVICE_PHONE = "phhone"