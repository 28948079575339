import React from "react"

import SearchField from "../../components/SearchField"

import IconButton from "../../components/IconButton"

import {stringBContainsStringA} from '../../utils/stringUtils'
import {downloadFile} from '../../utils/fileUtils'

import {connect} from "react-redux"

import pdfIcon from "./pdf-icon.png"

import styles from "./DocumentSearch.module.css"

class DocumentSearch extends React.Component{
    state = {
        searchText: ""
    }

    handleSearchFieldChangeText = searchText => this.setState({searchText})
    clearSearchField = () => this.setState({searchText: ""})

    getDocumentTiles = () => {
        const {documents} = this.props
        const {searchText} = this.state
        if (!searchText.trim()) return null
        return documents.documentIds
                        .map(documentId => documents.documentsById[documentId])
                        .filter(doc => stringBContainsStringA(searchText, doc.searchString))
                        .map( doc => <DocumentTile 
                                        key={doc.id}
                                        title={doc.title}
                                        name={doc.name}
                                        description={doc.description}
                                        createdAt={doc.createdAt} 
                                        url={doc.source}
                                    />
                        )
    }

    render(){
        const {searchText} = this.state
        return (    
            <div className={styles.container}>
                <div className={styles.searchField}>
                    <SearchField 
                        text={searchText}
                        placeholder={"Search By Country, Document Name or Content"}
                        onChangeText={this.handleSearchFieldChangeText}
                        onPressClose={this.clearSearchField}
                    />
                </div>
                <div className={styles.documentList}>
                    {this.getDocumentTiles()}
                </div>
        </div>
        )
    }
}

class DocumentTile extends React.Component {
    state = {
        showDescription: false
    }

    toggleShowDescription = () => this.setState({showDescription: !this.state.showDescription})
    render(){
        const {title, createdAt, description, url, name} = this.props
        const {showDescription} = this.state 
        const handleOpenUrl = () => window.open(url,'_blank');
        const handleDownloadFile = () => downloadFile(url, name)
        return (

            <div className={["raised", styles.documentTile].join(" ")}>
                <div className={styles.documentInner}>
                    <img src={pdfIcon}/>
                    <div>
                        <div className={styles.documentTitle}>{title}</div>
                        <div className={styles.attribute}>{createdAt}</div>
                        <div className={styles.documentActions}>
                            <IconButton icon="more-horiz" onPress={this.toggleShowDescription} tooltip="more"/>
                            <IconButton icon="open-in-new" onPress={handleOpenUrl} tooltip="open in a new tab"/>
                            <IconButton icon="cloud-download" onPress={handleDownloadFile} tooltip="download"/>
                        </div>
                    </div>
                </div>
                {
                    showDescription ?
                    <div className='moreDescription'>{description}</div>
                    :
                    null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    documents: state.documents
})


export default connect(mapStateToProps)(DocumentSearch)