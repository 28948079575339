import React from 'react'
import PropTypes from 'prop-types'
import {Popup} from "react-mapbox-gl";
import styles from "./PointTooltip.module.css";
import {connect} from "react-redux";
import {
    SYMBOL_MAPBOX_LAYER_TYPE,
    LINE_MAPBOX_LAYER_TYPE,
    FILL_MAPBOX_LAYER_TYPE
} from "../../constants/mapBoxConstants"

export class PointTooltip extends React.Component {
    /** Tooltip shown when hovering over a point
     */

    render() {
        const {feature} = this.props;
        if (!feature.properties.name) return null
        return <Popup
                className={styles.container}
                coordinates={feature.geometry.coordinates}
                offset={{
                    'bottom-left': [12, -38], 'bottom': [0, -18], 'bottom-right': [-12, -38]
                }}
            >
                <h3>{feature.properties.name}</h3>
            </Popup>
    }
}

export default PointTooltip;
