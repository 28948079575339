/**
 * Purpose: to specify the commands that are allowed on the Map objects in state
 */

export const SAVE_MAP = "SAVE_MAP"
export const UPDATE_MAP_POSITION = "UPDATE_MAP_POSITION"

export const saveMap = mapbox => {
    return {
        type: SAVE_MAP,
        payload: {
            mapbox
        }
    }
} 

export const updateMapPosition = (center, zoom) => {
    return {
        type: UPDATE_MAP_POSITION,
        payload: {
            center,
            zoom
        }
    }
}