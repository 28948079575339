import React from "react"
import Icon from "../Icon"
import styles from "./IconButton.module.css"

import ReactTooltip from "react-tooltip";

class IconButton extends React.PureComponent{
    constructor(props){
        super(props)
        this.button =React.createRef()
        this.hasMouseOver = false 
    }
    handleShowTooltip = () => {
        const {tooltip} = this.props
        if (!tooltip) return
        this.hasMouseOver = true
        const node = this.button
        setTimeout(() => {
            if (this.hasMouseOver) ReactTooltip.show(node);
        }, 300)
    };
    handleHideTooltip = () => {
        const {tooltip} = this.props
        if (!tooltip) return
        this.hasMouseOver = false
        const node = this.button
        ReactTooltip.hide(node);
    }
    render(){
        const {icon, onPress, tooltip} = this.props
        return <span 
                    ref={ref => this.button = ref}
                    className={styles.container} 
                    onMouseEnter={this.handleShowTooltip}
                    onMouseLeave={this.handleHideTooltip}
                    onClick={onPress}
                    data-tip={tooltip}
                >
                    <Icon  icon={icon}/>
                    <ReactTooltip 
                        event="no-event"
                        className={styles.tooltip}
                        offset={
                            {
                                right: "30px",
                                bottom: "65px"
                            }
                        }
                    />
                </span>
    }
}

export default IconButton