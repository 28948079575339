import React from 'react';
import Map from '../../components/Map';
import MapPopup from '../../components/MapPopup';
import PointToolTip, { PointTooltip } from '../../components/PointTooltip'
import CountrySelectionLayer from "../CountrySelectionLayer"
import SelectedLayers from '../../containers/SelectedLayers'
import {ScaleControl} from 'react-mapbox-gl'

import {CARIBBEAN_CENTER_COORDS, CARIBBEAN_EXTENT, SYMBOL_MAPBOX_LAYER_TYPE} from '../../constants/mapBoxConstants'

import styles from './MainMap.module.css'

import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

import {deepCompare} from "../../utils/objectUtils"
import {mergeExtent} from "../../utils/mapUtils"

export class MainMap extends React.Component {

  constructor(props){
    super(props)
    const {system} = this.props
    const latitude = this.props.latitude ? this.props.latitude : CARIBBEAN_CENTER_COORDS.latitude
    const longitude = this.props.longitude ? this.props.longitude : CARIBBEAN_CENTER_COORDS.longitude
    const zoom = this.props.zoom ? this.props.zoom : system.mapZoomLevels.caribbean
    this.state = {
      zoom,
      latitude,
      longitude,
      selectedCountryIds: null,
      clickFeatures : [],
      hoverFeature: null,
      clickLocation: null
    }
  }

  static defaultProps = {
    multiSelectCountries: false
  }

  static getDerivedStateFromProps(props, state) {
    const {countries, system} = props
    let update = null
    let extent = CARIBBEAN_EXTENT
    //if the selected countries changes
    if (!deepCompare(countries.selectedIds, state.selectedCountryIds)){
      //if one or more countries are selected
      if (Object.keys(countries.selectedIds).length >= 1) {
        //loop through the selected countries, pulling out their id on at a time
        for (const id of Object.keys(countries.selectedIds)) {
          //find the country
          const selectedCountry = countries.countriesById[id];
          if (!extent) {
            extent = selectedCountry.boundingBox
          } else {
            extent = mergeExtent(extent, selectedCountry.boundingBox);
          }
        }
      } else {
          //if no countries have been selected
          if (state.selectedCountryIds !== null) {
            update = {...CARIBBEAN_CENTER_COORDS, zoom: system.mapZoomLevels.caribbean}
          }
      }
      if (state.selectedCountryIds === null) {
          extent = null
      }
      return {...update, selectedCountryIds: countries.selectedIds, extent:extent}
    } else return null
  }


  getCountrySelectionLayers = () => {
    const {countries, multiSelectCountries} = this.props
    // let's load selected one first
    let selected = Object.keys(countries.selectedIds)
    countries.countryIds.map(
        countryId => {
        if (!selected.includes(countryId)){
            selected.push(countryId)
        }
    })
    return selected.map(
      countryId => <CountrySelectionLayer
                      key={countryId}
                      countryId={countryId}
                      multiSelect={multiSelectCountries}
                    />
      )
  }

  handleClick = (lngLat, clickFeatures) => {
    this.setState({clickFeatures, clickLocation: lngLat })
  }

  handleHover = (lngLat, hoverFeatures) => {
    if (hoverFeatures.length === 0) {
      if (this.state.hoverFeature) this.setState({hoverFeature: null})
      return
    }
    const hoverFeature = hoverFeatures[0]
    if (this.state.hoverFeature && (hoverFeature.id === this.state.hoverFeature.id)) return
    this.setState({hoverFeature})
  } 

  handleMapLoaded = (map) => {
    const {actions} = this.props
    actions.saveMap(map)
  }

  /** When map dragged
   * @param map
   */
  handleMapMove = (map) => {
    const {actions} = this.props
    actions.updateMapPosition(map.getCenter(), map.getZoom())
  }

  currentFeatureRemoved = () => {
    this.setState({clickFeatures: [] , clickLocation: null})
  }

  render(){
    const {zoom, longitude, latitude, clickFeatures, clickLocation, hoverFeature, selectedCountryIds} = this.state
    const {selectedLayerIds, countries} = this.props
    const scaleStyle = Object.keys(selectedLayerIds).length > 0 ?
                        `${styles.scaleBar} ${styles.legendVisible}`
                        :
                        Object.keys(countries.selectedIds).length > 0?
                        `${styles.scaleBar} ${styles.navbarVisible}`
                        :
                        styles.scaleBar
    return (
        <div className={styles.container}>
          <Map
            zoom={zoom}
            longitude={longitude}
            latitude={latitude}
            extent={this.state.extent}
            onClick={this.handleClick}
            onHover={this.handleHover}
            onMapLoaded={this.handleMapLoaded}
            onMove={this.handleMapMove}
            onScroll={this.handleMapMove}
          >
            {this.getCountrySelectionLayers()}
            <SelectedLayers />
            <MapPopup
              lngLat={clickLocation}
              features={clickFeatures}>
            </MapPopup>
            <ScaleControl  className={scaleStyle} />
            {
              hoverFeature && 
              hoverFeature.layer.type === SYMBOL_MAPBOX_LAYER_TYPE && 
              ( 
                (clickFeatures.length === 0) ||
                (clickFeatures[0].id !== hoverFeature.id) || 
                (clickFeatures[0].source !== hoverFeature.source)
              )?
              <PointTooltip feature={hoverFeature}/>
              : 
              null
            }
          </Map>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  system: state.system,
  countries: state.countries,
  selectedLayerIds: state.layers.selectedIds
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

MainMap.propTypes = {
  actions: PropTypes.shape({
    selectCountry: PropTypes.func.isRequired,
    saveMap: PropTypes.func.isRequired,
    updateMapPosition: PropTypes.func.isRequired,
  }).isRequired,
  system: PropTypes.shape({
    mapZoomLevels: PropTypes.shape({
      caribbean: PropTypes.number.isRequired,
      country: PropTypes.number.isRequired
    }).isRequired
  }),
  countries: PropTypes.shape({
    countriesById: PropTypes.object.isRequired,
    countryIds: PropTypes.array.isRequired,
    selectedIds: PropTypes.object.isRequired,
    lastSelectedId: PropTypes.string
  }).isRequired,
  multiSelectCountries: PropTypes.bool,
  children: PropTypes.node
}
export default connect(mapStateToProps, mapDispatchToProps)(MainMap);
