import React from 'react'
import SearchDropdown from '../../components/SearchDropdown'
import TagTray from '../../components/TagTray'
import CheckBox from '../../components/CheckBox'

import styles from './CountrySearch.module.css'
import {stringBContainsStringA, compareStrings} from '../../utils/stringUtils'

import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

export class CountrySearch extends React.Component {

    static defaultProps = {
        multiSelectCountries: false,
        onToggleMultiSelectCountries: () => {}
    }

    constructor(props){
        super(props)
        let searchText = ''
        let currentSelected = Object.keys(this.props.countries.selectedIds)
        if (currentSelected.length === 1) {
            searchText = this.getCountryNameByCode(currentSelected[0])
        }
        this.state = {
            matchingCountries: [],
            searchText: searchText
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let currentSelected = Object.keys(this.props.countries.selectedIds);
        let prevSelected = Object.keys(prevProps.countries.selectedIds);
        if (JSON.stringify(currentSelected) !== JSON.stringify(prevSelected)) {
            // if selected is 1, fill the search field
            if (currentSelected.length === 1) {
                this.setSearchTextByCountryCode(currentSelected[0])
            }
        }
    }

    /** return country name by the code
     * @param code
     */
    getCountryNameByCode = code => {
        const countries = Object.values(this.props.countries.countriesById)
        let country = countries.filter(
            country => stringBContainsStringA(code, country.id)
        )
        if (country[0]) {
            return country[0].name
        } else {
            return ''
        }
    }

    /** Setup search text by country code
     * @param code
     */
    setSearchTextByCountryCode = code => {
        let countryName = this.getCountryNameByCode(code)
        if (countryName) {
            this.setState({searchText: countryName})
        }
    }

    filterCountries = searchText => {
        const countries = Object.values(this.props.countries.countriesById)
        let matchingCountries
        if (!searchText.trim()) matchingCountries = []
        else {
            matchingCountries = countries.filter(
                country => stringBContainsStringA(searchText, country.name)
            )
        }
        this.setState({matchingCountries})
    }

    selectCountry = id => {
        const {actions, multiSelectCountries, countries} = this.props
        actions.selectCountry(id, multiSelectCountries, countries.lastSelectedId)
    }

    deselectCountry = id => {
       const {actions} = this.props
       actions.deselectCountry(id)
    }

    deselectAllCountries = () => {
        const {actions} = this.props
        actions.deselectAllCountries()
    }

    render(){
        const {matchingCountries, searchText} = this.state
        const {countries, multiSelectCountries, onToggleMultiSelectCountries} = this.props
        const allCountries = Object.values(countries.countriesById).sort(( countryA, countryB) => {
                                                        return compareStrings(countryA.name, countryB.name)
                                                    })
                                                
        const selectedCountryItems = Object.keys(countries.selectedIds).map(selectedId => ({...countries.countriesById[selectedId]}))
        return (
            <div className={[styles.container, 'raised'].join(" ")}>
                <SearchDropdown
                    placeholder="Select Country By Name"
                    onChangeText={this.filterCountries}
                    searchText={searchText}
                    results={matchingCountries}
                    onSelectResult={this.selectCountry}
                    onDeselectResult={this.deselectCountry}
                    resultDisplayProperty={'name'}
                    displayUnfilteredListOnEmptySearchFieldFocus={true}
                    unfilteredOptions={allCountries}
                    selectedResults={{...this.props.countries.selectedIds}}
                    multiSelect={multiSelectCountries}
                    onDeselectAll={this.deselectAllCountries}
                />
                {
                    multiSelectCountries || selectedCountryItems.length > 1
                    ?
                        <TagTray
                            items={selectedCountryItems}
                            onPressCloseTag={this.deselectCountry}
                        />
                    :
                    null
                }
                <div className={styles.checkBoxContainer}>
                    <CheckBox
                        checked={multiSelectCountries}
                        onSelect={onToggleMultiSelectCountries}
                        onDeselect={()=> {
                            onToggleMultiSelectCountries(false);
                            this.deselectAllCountries()
                        }}
                        label="select multiple countries"
                        labelPosition="left"
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    countries: state.countries
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

CountrySearch.propTypes = {
    actions: PropTypes.shape({
        selectCountry: PropTypes.func.isRequired,
        deselectCountry: PropTypes.func.isRequired,
        deselectAllCountries: PropTypes.func.isRequired
    }).isRequired,
    countries:  PropTypes.shape({
        countryIds: PropTypes.array.isRequired,
        countriesById: PropTypes.object.isRequired,
        selectedIds: PropTypes.object.isRequired,
        lastSelectedId: PropTypes.string
    }).isRequired,
    multiSelectCountries: PropTypes.bool,
    onToggleMultiSelectCountries: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(CountrySearch);