/**
 *  Purpose: the modifications that are carried on the Document objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {HTML_LINK_SOURCE} from "../constants/pycswConstants"

// STATE
// documents:{
//   documentsById:{},
//   documentIds: [],
//   selected: null
// }
const DOCUMENT_DATA_TYPE = "document"

const documentsReducer = (state = initialState.documents, action) => {
    const {type, payload} = action;
    let documentsById = {...state.documentsById}

    switch (type){

        case types.SAVE_LAYERS: {
            if (!payload.layers){
                return state
            }
            const records = payload.layers ? [...payload.layers] : []
            
            if (typeof records !== 'object'){
                return state
            }
            records.forEach(record => {
                record = record['dc:type'] === DOCUMENT_DATA_TYPE ? formatDocument(record) : null
                if (!record) return
                 documentsById[record.id] = record
            })
            return {
                ...state,
                documentsById,
                documentIds: Object.keys(documentsById)
            }
        }

        default: return state
    }
}

const formatDocument = doc => {
    if ((typeof doc !== 'object') || !doc['dc:source']){
        logError(`documentsReducer > formatDocument: document is not an object or is in the wrong format ${JSON.stringify(doc)}`)
        return null
    }
    else {
        
        //direct attributes
        const id = doc['dc:identifier']
        const name = doc['dc:source']
        const description = doc['dct:abstract']
        const createdAt = doc['dc:date']
        const lastModifiedAt = doc['dct:modified']
        const title = doc['dc:title']

        //computed attributes
        const nameFragments = name.split(".")
        const format = nameFragments[nameFragments.length - 1]
        if (format.includes("_")){
            logError(`documentReducer > formatDocument: document ${id} is not in one of the allowed formats ${JSON.stringify(doc)}`)
        }
        const tags = doc['dc:subject'] ? doc['dc:subject'].filter(tag => typeof tag === 'string').map(tag => tag.toLowerCase()) : []
        const searchString =[title, name, description, ...tags].join(" ")
        //data sources
        let source
        if (doc['dct:references']) {
                if (doc['dct:references'].length && (doc['dct:references'].length >= 1)){
                    const sourceLink = doc['dct:references'].find(doc => {
                        return doc['@scheme'] === HTML_LINK_SOURCE
                    })
                    if (sourceLink) source = sourceLink["#text"]
                }
                else source = doc['dct:references']["#text"]
        } else {
            logError(`documentReducer > formatDocument: document ${id} has no sources so it cannot be linked to ${JSON.stringify(doc)}`)
            return null
        }

        return ({
            id, 
            name,
            description,
            title,
            createdAt,
            lastModifiedAt,
            format,
            tags,
            source,
            searchString
        })
    }
}

export default documentsReducer

