import {logError} from "../utils/errorHandlingUtils"

export const SAVE_LAYERS = 'SAVE_LAYERS'
export const SAVE_LAYER_CATEGORIES = 'SAVE_LAYER_CATEGORIES'
export const SELECT_LAYER = 'SELECT_LAYER'
export const DESELECT_LAYER = 'DESELECT_LAYER'
export const SAVE_STYLE = 'SAVE_STYLE'
export const TOGGLE_LAYER_LOADED = 'TOGGLE_LAYER_LOADED'
export const CHANGE_LAYER_ORDER = 'CHANGE_LAYER_ORDER'

export const changeLayerOrder = (layerId, moveUp = true) => {
    return {
        type: CHANGE_LAYER_ORDER,
        payload: {
            layerId,
            moveUp
        }
    }
}

export const saveLayers = (layers) => {
    return {
        type: SAVE_LAYERS,
        payload: {
            layers
        }
    }
}

export const saveLayerCategories = categories => {
    return {
        type: SAVE_LAYER_CATEGORIES,
        payload: {
            categories
        }
    }
}

export const selectLayer = (layerId) => {
    return {
        type: SELECT_LAYER,
        payload: {
            layerId
        }
    }
}

export const deselectLayer = (layerId) => {
    return {
        type: DESELECT_LAYER,
        payload: {
            layerId
        }
    }
}

export const saveStyle = (style, layerId) => {
    return {
        type: SAVE_STYLE,
        payload: {
            style,
            layerId
        }
    }
}

export const toggleLayerLoaded = (layerId, loaded= true) => {
    return {
        type: TOGGLE_LAYER_LOADED,
        payload: {
            layerId,
            loaded
        }
    }
}
export const fetchSaveLayers = () => {
    return async (dispatch) =>{
        const link = "https://geocris2.cdema.org/catalog/?typenames=csw:Record&service=CSW&version=3.0.0&request=GetRecords&outputformat=application/json&ElementSetName=full" 
        try {
            let json
            const response = await fetch(link, {
                method: "GET"
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            json = await response.json()

            const results = json['csw30:GetRecordsResponse']['csw30:SearchResults']['csw30:Record']
            dispatch(saveLayers(results))
        } catch (e){
            logError(`Failed to get and dispatch layers, ${e}`)
        }
    }
}

export const fetchSaveStyle = layerId => {
    return async (dispatch) =>{
        const link = `https://geocris2.cdema.org/vector_styles/${layerId}` 
        try {
            const response = await fetch(link, {
                method: "GET"
            })
            if (!response.ok) {
                throw new Error("something went wrong loading the layer");
            }
            const style = await response.json()
            dispatch(saveStyle(style, layerId))
        } catch (e){
            logError(`Failed to get and dispatch layers, ${e}`)
        }
    }
}