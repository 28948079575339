import React from 'react';
import Main from '../Main'
import NotAuthenticated from "../NotAuthenticated"
import LoadingScreen from "../../components/LoadingScreen"

import './App.css';
import {logError} from '../../utils/errorHandlingUtils'

import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

import countries from "../../data/countries.json"
import categories from "../../data/categories.json"

import {
  BrowserRouter as Router, 
  Route, 
  Switch,
} from 'react-router-dom'

import {configureScope} from '../../config/sentry'
configureScope()

export class App extends React.Component {

  loaded = false
  UNSAFE_componentWillMount() {
    const {actions} = this.props
    actions.setVersion()
    actions.setDevice()
  }
  
  componentDidMount = async () => {
    const {actions} = this.props
    actions.toggleLoading(true)
    await Promise.all([
      actions.saveCountries(countries),
      actions.saveLayerCategories(categories),  
      actions.fetchSaveLayers()
    ])
    actions.toggleLoading(false)
    configureScope()
    actions.toggleLoaded(true)
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logError(error, errorInfo);
  }

  render(){
    const {user, system} = this.props
    
    return (
        <React.Fragment>
          {
            system.loaded ?
            <React.Fragment>
            {
              user.authenticated 
              ?
                
                <Router>
                    <Switch>
                        <Route path={"/"} component={Main}/>
                    </Switch>
                </Router>
              
              :
                <Router>
                    <Switch>
                        <Route path={"/"} component={NotAuthenticated}/>
                    </Switch>
                </Router>
            }
            </React.Fragment>
            :
            null
          }
          {
            system.isLoading ?
              <LoadingScreen />
              :
              null
          }
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  system: state.system
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

App.propTypes = {
  actions: PropTypes.shape({
    saveCountries: PropTypes.func.isRequired,
    setVersion: PropTypes.func.isRequired,
    setDevice: PropTypes.func.isRequired,
    saveLayers: PropTypes.func.isRequired,
    saveLayerCategories: PropTypes.func.isRequired
  }).isRequired,
  user: PropTypes.shape({
    authenticated: PropTypes.bool.isRequired
  }).isRequired
}
export default connect(mapStateToProps, mapDispatchToProps)(App);