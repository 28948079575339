import {persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import countries from './countries'
import documents from "./documents"
import layers from './layers'
import system from './system'
import user from './user'
import map from './map'
import {
    POINT_GEOMETRY_TYPE,
    LINE_GEOMETRY_TYPE,
    RASTER_GEOMETRY_TYPE,
    POLYGON_GEOMETRY_TYPE
} from "../constants/mapBoxConstants"

import {version} from "../config/release"

const config = {
    key: 'primary',
    whitelist: [],
    storage
};

export const initialState = {
    countries: {
        countriesById: {},
        countryIds: [],
        selectedIds: {},
        lastSelectedId: null
    },
    layers: {
        layersById: {},
        layersByCountryId: {},
        adminLevelsByCountryId: {},
        layerIds: {},
        selectedIds: {},
        selectedIdsOrder: {
            [POINT_GEOMETRY_TYPE]: [],
            [LINE_GEOMETRY_TYPE]: [],
            [POLYGON_GEOMETRY_TYPE]: [],
            [RASTER_GEOMETRY_TYPE]:[],
            highest: null,
            lowest: null
        },
        loadedIds: {},
        layerCategoriesById: {}
    },
    documents: {
        documentsById: {},
        documentIds: [],
        selected: null
    },
    system: {
        version,
        device: null,
        loaded: false,
        isLoading: false,
        mapZoomLevels: {
            caribbean: null,
            subregion: null,
            country: null
        },
        name: "CDEMA Geocris"
    },
    user: {
        authenticated: false
    },
    map: {
        mapbox: null,
        center: {},
        zoom: null
    }
}

const rootReducer = persistCombineReducers(config, {
    countries,
    documents,
    layers,
    system,
    user,
    map
});

export default rootReducer