import React from "react"
import NavigationTile from "../../components/NavigationTile"
import Modal from 'react-modal'
import LayerPicker from "../LayerPicker"
import DocumentSearch from "../DocumentSearch"
import {ShareMap} from "../ShareMapPanelOption"

import styles from "./MobileNavigationBar.module.css"
import {connect} from "react-redux"

const MAP_TAB = 'MAP_TAB'
const LAYERS_TAB = 'LAYERS_TAB'
const DOCUMENTS_TAB = 'DOCUMENTS_TAB'
const SHARE_TAB = 'SHARE_TAB'

class MobileNavigationBar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            layersModalOpen: false,
            documentsModalOpen: false,
            shareModalOpen: false,
            slideUp: false,
            activeTab: MAP_TAB
        }
    }

    slide = () => {
       setTimeout( () => this.setState({slideUp: true}), 50)
    }
    handleOpenLayersModal = () => this.setState({
        layersModalOpen: true,
        documentsModalOpen: false,        
        shareModalOpen: false,
        slideUp: false,
        activeTab: LAYERS_TAB
    }, this.slide)

    
    handleOpenDocumentsModal = () => this.setState({
        layersModalOpen: false,
        documentsModalOpen: true,
        shareModalOpen: false,
        slideUp: false,
        activeTab: DOCUMENTS_TAB
    }, this.slide)

    handleCloseModal = () => this.setState({
        layersModalOpen: false, 
        documentsModalOpen: false,
        shareModalOpen: false,
        slideUp: false,
        activeTab: MAP_TAB
    })

    nativeShare = () => this.setState({
            documentsModalOpen: false,
            layersModalOpen: false,
            shareModalOpen: false,
            slideUp: false,
            activeTab: SHARE_TAB
        }, async () => {
        try {
                await navigator.share({
                    title: 'Geocris',
                    url: window.location.href
                })
        } catch (err) {
            console.error("Native share failed: ", err.message)
        } finally {
            this.setState({activeTab: MAP_TAB})
        }
    })

    handleOpenShareModal = () => {
        if (navigator.share) this.nativeShare()    
        else this.setState({
                layersModalOpen: false, 
                documentsModalOpen: false,
                shareModalOpen: true,
                slideUp: false,
                activeTab: SHARE_TAB
            }, this.slide)
    }

    render(){
        const {layersModalOpen, documentsModalOpen, shareModalOpen, slideUp, activeTab} = this.state
        const {countries} = this.props
        if (Object.keys(countries.selectedIds).length === 0) return ""
        const modalStyle = slideUp ? `${styles.modal} ${styles.slide}` : styles.modal
        return (
        <div className={styles.container}>
            <NavigationTile 
                icon="place"
                title="Map"
                onClick={this.handleCloseModal}
                selected={activeTab === MAP_TAB}
            />
            <NavigationTile 
                icon="layers"
                title="Layers"
                onClick={this.handleOpenLayersModal}
                selected={activeTab === LAYERS_TAB}
            />
            <NavigationTile 
                icon="insert-drive-file"
                title="Documents"
                onClick={this.handleOpenDocumentsModal}
                selected={activeTab === DOCUMENTS_TAB}
            />
            <NavigationTile 
                icon="share"
                title="Share"
                onClick={this.handleOpenShareModal}
                selected={activeTab === SHARE_TAB}
            />

            {
                layersModalOpen ?
                <Modal
                    ariaHideApp={false}
                    isOpen={layersModalOpen}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Layers Modal"
                    className={modalStyle}
                    overlayClassName={['overlay', styles.modalOverlay].join(" ")}
                >
                    <LayerPicker />
                </Modal>
                : null
            }

            {
                documentsModalOpen ?
                <Modal
                    ariaHideApp={false}
                    isOpen={documentsModalOpen}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Documents Modal"
                    className={`${modalStyle} ${styles.documentsModal}`}
                    overlayClassName={['overlay', styles.modalOverlay].join(" ")}
                >
                    <DocumentSearch />
                </Modal>
                : null
            }
            
            {
                shareModalOpen ?
                <Modal
                    ariaHideApp={false}
                    isOpen={shareModalOpen}
                    onRequestClose={this.handleCloseModal}
                    contentLabel="Share Modal"
                    className={`${modalStyle} ${styles.shareModal}`}
                    overlayClassName={['overlay', styles.modalOverlay].join(" ")}
                >
                    <ShareMap />
                </Modal>
                : null
            }
        </div>
        )
    }
}

const mapStateToProps = state => ({
    countries: state.countries
})

export default connect(mapStateToProps)(MobileNavigationBar)