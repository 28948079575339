/**
 * Purpose: to specify the commands that are allowed on the System objects in state
 */

export const SET_VERSION = "SET_VERSION"
export const SET_DEVICE = "SET_DEVICE"
export const TOGGLE_LOADED = "TOGGLE_LOADED"
export const TOGGLE_LOADING = "TOGGLE_LOADING"

export const setVersion = () => {
    return {
        type: SET_VERSION
    }
} 

export const setDevice = () => {
    return {
        type: SET_DEVICE
    }
} 

export const toggleLoaded = loaded => {
    return {
        type: TOGGLE_LOADED,
        payload: {
            loaded
        }
    }
} 

export const toggleLoading = isLoading => {
    return {
        type: TOGGLE_LOADING,
        payload: {isLoading}
    }
}