
import {
    MED_SCREEN_MAX_WIDTH,
    TABLET_SCREEN_MAX_WIDTH, 
    PHONE_SCREEN_MAX_WIDTH,
    DEVICE_BIG_LAPTOP,
    DEVICE_LAPTOP,
    DEVICE_TABLET,
    DEVICE_PHONE
} from "../constants/deviceConstants"

export const estimateDeviceType = () => {
    const { innerWidth } = window;
    let device 
    switch(true){
        case (innerWidth > MED_SCREEN_MAX_WIDTH): device = DEVICE_BIG_LAPTOP; break
        case (innerWidth <= MED_SCREEN_MAX_WIDTH && innerWidth > TABLET_SCREEN_MAX_WIDTH): device = DEVICE_LAPTOP; break;
        case (innerWidth <= TABLET_SCREEN_MAX_WIDTH && innerWidth > PHONE_SCREEN_MAX_WIDTH): device = DEVICE_TABLET;break;
        case (innerWidth <= PHONE_SCREEN_MAX_WIDTH): device = DEVICE_PHONE; break;
        default: device = DEVICE_LAPTOP 
    }
    return device
}