import {DEVICE_BIG_LAPTOP, DEVICE_LAPTOP, DEVICE_PHONE, DEVICE_TABLET} from "./deviceConstants"

export const CARIBBEAN_CENTER_COORDS = {
    longitude:-73.7948492627536,
    latitude:18.48492517832554
}
export const CARIBBEAN_EXTENT = [
    [-92.5763852, 21.8754863], [-56.9993964, 14.6838007]]

export const MAP_DEFAULT_ZOOM_LEVELS = {
    [DEVICE_BIG_LAPTOP]: 5,
    [DEVICE_LAPTOP] : 4.7,
    [DEVICE_TABLET] : 4,
    [DEVICE_PHONE] : 3.2
}

export const MAP_SUBREGION_ZOOM_LEVELS = {
    [DEVICE_BIG_LAPTOP]: 7,
    [DEVICE_LAPTOP] : 6,
    [DEVICE_TABLET] : 6,
    [DEVICE_PHONE] : 5
}

export const MAP_COUNTRY_ZOOM_LEVELS = {
    [DEVICE_BIG_LAPTOP]: 10,
    [DEVICE_LAPTOP] : 9,
    [DEVICE_TABLET] : 7,
    [DEVICE_PHONE] : 7
}

export const POINT_GEOMETRY_TYPE = "point"
export const LINE_GEOMETRY_TYPE = "line"
export const POLYGON_GEOMETRY_TYPE = "polygon"
export const RASTER_GEOMETRY_TYPE = "raster"

export const SYMBOL_MAPBOX_LAYER_TYPE = "symbol"
export const LINE_MAPBOX_LAYER_TYPE = "line"
export const FILL_MAPBOX_LAYER_TYPE = "fill"


export const GEOMETRY_TO_MAPBOX_LAYER_TYPE = {
    [POINT_GEOMETRY_TYPE] : SYMBOL_MAPBOX_LAYER_TYPE,
    [LINE_GEOMETRY_TYPE] : LINE_MAPBOX_LAYER_TYPE,
    [POLYGON_GEOMETRY_TYPE] : FILL_MAPBOX_LAYER_TYPE,
    [RASTER_GEOMETRY_TYPE] : RASTER_GEOMETRY_TYPE,
}