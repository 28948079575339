/**
 *  Purpose: the modifications that are carried on the User objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
// STATE
// user:{
//   authenticated: false
// }


const userReducer = (state = initialState.user, action) => {
    const {type, payload} = action;
    switch (type){
        default: return state
    }
}

export default userReducer