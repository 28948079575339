import React from 'react'
import PropTypes from 'prop-types'
import {Popup} from "react-mapbox-gl";
import styles from "../../components/MapPopup/MapPopup.module.css";
import {connect} from "react-redux";
import {
    SYMBOL_MAPBOX_LAYER_TYPE,
    LINE_MAPBOX_LAYER_TYPE,
    FILL_MAPBOX_LAYER_TYPE
} from "../../constants/mapBoxConstants"

export class MapPopup extends React.Component {
    /** Class handling popup for Map
     */

    render() {
        const {features, layers,lngLat} = this.props;
        let displayFeatures = features.filter(
            feature => [SYMBOL_MAPBOX_LAYER_TYPE, LINE_MAPBOX_LAYER_TYPE, FILL_MAPBOX_LAYER_TYPE].includes(feature.layer.type)
        )
        if (displayFeatures.length === 0) return null
        const feature = displayFeatures[0]
        if (!feature) return null
        let coordinates = lngLat
        let offset = {}
        if (feature.layer.type === SYMBOL_MAPBOX_LAYER_TYPE){
            coordinates = feature.geometry.coordinates
            offset = {
                'bottom-left': [12, -38], 'bottom': [0, -18], 'bottom-right': [-12, -38]
            }
        }
        let selectedSourceIds = Object.keys(layers.selectedIds).map(id => `${id}-source`)
        let properties = {}
        if (selectedSourceIds.includes(feature.layer.source)) {
            properties = {id: displayFeatures[0].id, ...displayFeatures[0].properties}
        }
        return Object.keys(properties).length > 0 ?
            <Popup
                className={styles.popupContainer}
                coordinates={coordinates}
                offset={offset}
            >
                {properties.name && (properties.name !== 'null') ? <h2>{properties.name}</h2> : null}
                {
                    Object.keys(properties).map(key => {
                        const value = properties[key]
                        if (value !== "null") {
                            return <p key={`${displayFeatures[0].id}-icon-info-${key}`}>{`${key}: ${value}`}</p>
                        }
                    })
                }
            </Popup> : null
    }
}


MapPopup.propTypes = {
    features: PropTypes.array,
    layers: PropTypes.shape({
        layersById: PropTypes.object.isRequired,
        layersByCountryId: PropTypes.object.isRequired,
        adminLevelsByCountryId: PropTypes.object.isRequired,
        layerIds: PropTypes.array.isRequired,
        selectedIds: PropTypes.object.isRequired
    })
}

const mapStateToProps = state => ({
    layers: state.layers
})

export default connect(mapStateToProps)(MapPopup);
