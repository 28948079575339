import React from 'react'
import PropTypes from 'prop-types'
import {logError} from "../../utils/errorHandlingUtils"

import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import CloudDownload from '@material-ui/icons/CloudDownload'
import DonutSmall from '@material-ui/icons/DonutSmall'
import Group from '@material-ui/icons/Group'
import Image from '@material-ui/icons/Image'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Layers from '@material-ui/icons/Layers'
import Lock from '@material-ui/icons/Lock'
import Menu from '@material-ui/icons/Menu'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import OpenInNew from '@material-ui/icons/OpenInNew'
import Person from '@material-ui/icons/Person'
import Phone from '@material-ui/icons/Phone'
import PictureAsPdf from '@material-ui/icons/PictureAsPdf'
import Place from '@material-ui/icons/Place'
import Search from '@material-ui/icons/Search'
import Share from '@material-ui/icons/Share'


/**
 * Documentation here: https://www.npmjs.com/package/@material-ui/icons
 * 
 * Note: This component uses a switch statement to determine which icon to display
 * 
 * Important:
 *     :  If the icon you want is not imported above^, import it using the 
 *     :  currently used import style `import IconName from '@material-ui/icons/DonutSmall'`
 *     :  in the style `import {IconName} from '@material-ui/icons'` will load all available icons
 *     :  into the software and bloat the program 
 * 
 */

function Icon ({icon="donut-small", customStyles={}}){
    if (typeof icon !== 'string'){
        logError(`Icon: icon supplied is not a string ${icon}`)
        return null
    }
    icon = icon.toLowerCase()
    let SelectedIcon = DonutSmall
    switch (icon) {
        case 'arrow-back': SelectedIcon = ArrowBack; break;
        case 'arrow-drop-down' : SelectedIcon = ArrowDropDown; break;
        case 'arrow-drop-up' : SelectedIcon = ArrowDropUp; break;
        case 'check' : SelectedIcon = Check; break;
        case 'close' : SelectedIcon = Close; break;
        case 'cloud-download' : SelectedIcon = CloudDownload; break;
        case 'donut-small' : SelectedIcon = DonutSmall; break;
        case 'group' : SelectedIcon = Group; break;
        case 'image' : SelectedIcon = Image; break;
        case 'insert-drive-file' : SelectedIcon = InsertDriveFile; break;
        case 'keyboard-arrow-down' : SelectedIcon = KeyboardArrowDown; break;
        case 'keyboard-arrow-left' : SelectedIcon = KeyboardArrowLeft; break;
        case 'keyboard-arrow-right' : SelectedIcon = KeyboardArrowRight; break;
        case 'layers' : SelectedIcon = Layers; break;
        case 'lock' : SelectedIcon = Lock; break;
        case 'menu' : SelectedIcon = Menu; break;
        case 'more-horiz' : SelectedIcon = MoreHoriz; break;
        case 'open-in-new': SelectedIcon = OpenInNew; break;
        case 'person' : SelectedIcon = Person; break;
        case 'phone' : SelectedIcon = Phone; break;
        case 'picture-as-pdf' : SelectedIcon = PictureAsPdf; break;
        case 'place' : SelectedIcon = Place; break;
        case 'search' : SelectedIcon = Search; break;
        case 'share' : SelectedIcon = Share; break;
        default: SelectedIcon = DonutSmall
    }
    return (
        <SelectedIcon style={customStyles} className={'icon'}/>
    )
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    customStyles: PropTypes.object
}
export default Icon;