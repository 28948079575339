/**
 *  Purpose: the modifications that are carried on the Country objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {getTileJsonSource} from "../utils/mapUtils"
import {
    dataSourceExtensionMap, 
    HTML_LINK_SOURCE, 
    GEOJSON_DATA_SOURCE, 
    TILEJSON_DATA_SOURCE,
    WMS_DATA_SOURCE,
    TMS_DATA_SOURCE,
    GEOPACKAGE_DOWNLOAD_SOURCE
} from "../constants/pycswConstants"

import {
    ADMIN_STRING_IDENTIFIER,
    ADMIN_LEVEL_LAYER_TYPE,
    STANDARD_LAYER_TYPE,
    GEOJSON_DATA_SOURCE_TYPE 
} from "../constants/dataConstants"
import {
    POINT_GEOMETRY_TYPE,
    LINE_GEOMETRY_TYPE,
    RASTER_GEOMETRY_TYPE,
    POLYGON_GEOMETRY_TYPE
} from "../constants/mapBoxConstants"

// STATE
// layers:{
//   layersById:{},
//   layersByCountryId:{},
//   adminLevelsByCountryId: {},
    // selectedIdsOrder: {
    //     [POINT_GEOMETRY_TYPE]: [],
    //     [LINE_GEOMETRY_TYPE]: [],
    //     [POLYGON_GEOMETRY_TYPE]: [],
    //     [RASTER_GEOMETRY_TYPE]:[],
    //     highest: null,
    //     lowest: null
    // },
//   layerIds: [],
//   selectedIds: {}
// }

const LAYER_DATA_TYPE = "dataset"

const layersReducer = (state = initialState.layers, action) => {
    const {type, payload} = action;
    let layersById = {...state.layersById}
    let layersByCountryId = {...state.layersByCountryId}
    let adminLevelsByCountryId = {...state.adminLevelsByCountryId}
    let selectedIds = {...state.selectedIds}
    let selectedIdsOrder = {...state.selectedIdsOrder}
    let loadedIds = {...state.loadedIds}
    let layer 
    let result

    const deselectLayer = layerId => {
        delete selectedIds[layerId]
        delete loadedIds[layerId]
        layer = layersById[layerId] 
        selectedIdsOrder = {
            ...selectedIdsOrder,
            [layer.geometryType]:selectedIdsOrder[layer.geometryType].filter(
                id => id !== layerId
            )
        }
        selectedIdsOrder.highest = getHighestLayer(
            selectedIdsOrder[POINT_GEOMETRY_TYPE],
            selectedIdsOrder[LINE_GEOMETRY_TYPE],
            selectedIdsOrder[POLYGON_GEOMETRY_TYPE],
            selectedIdsOrder[RASTER_GEOMETRY_TYPE],
        )
        selectedIdsOrder.lowest = getLowestLayer(
            selectedIdsOrder[POINT_GEOMETRY_TYPE],
            selectedIdsOrder[LINE_GEOMETRY_TYPE],
            selectedIdsOrder[POLYGON_GEOMETRY_TYPE],
            selectedIdsOrder[RASTER_GEOMETRY_TYPE],
        )
        return {selectedIds, selectedIdsOrder}
    }
    switch (type){

        case types.SAVE_LAYERS: {
            if (!payload.layers){
                logError(`layersReducer > SAVE_LAYERS: payload.layers is not defined ${JSON.stringify(payload)}`)
                return state
            }
            const layers = payload.layers ? [...payload.layers] : []
            
            if (typeof layers !== 'object'){
                logError(`layersReducer > SAVE_LAYERS: payload.layers is not an array ${JSON.stringify(layers)}`)
                return state
            }
            layers.forEach(layer => {
                layer = layer['dc:type'] === LAYER_DATA_TYPE ? formatLayer(sanitizeLayer(layer)) : null
                if (!layer) return
                layersById[layer.id] = layer
                let adminLevel
                if (layer.type === ADMIN_LEVEL_LAYER_TYPE){
                    adminLevel = layer.id.split(ADMIN_STRING_IDENTIFIER)[1][0]
                    if (!adminLevelsByCountryId[layer.countryId]) adminLevelsByCountryId[layer.countryId] = {}
                    adminLevelsByCountryId[layer.countryId][adminLevel] = layer.id
                }
                if (!adminLevel || (Number(adminLevel > 0))) layersByCountryId[layer.countryId] = layersByCountryId[layer.countryId] ? [...layersByCountryId[layer.countryId], layer.id] : [layer.id]

            })
            return {
                ...state,
                layersById,
                layersByCountryId,
                adminLevelsByCountryId,
                layerIds: Object.keys(layersById)
            }
        }

        case types.SAVE_LAYER_CATEGORIES: {
            if (!payload.categories){
                logError(`layersReducer > SAVE_LAYER_CATEGORIES: payload.categories is not defined ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                layerCategoriesById: payload.categories
            }
        }

        case types.SELECT_LAYER: {
            if (!payload.layerId || (typeof payload.layerId !== "string" )){
                logError(`layersReducer > SELECT_LAYER: payload.layerId is not a string - ${JSON.stringify(payload)}`)
                return state
            }
            layer = layersById[payload.layerId] 
            if (!layer) return state
            selectedIdsOrder = {
                ...selectedIdsOrder,
                [layer.geometryType]: [
                    payload.layerId,
                    ...selectedIdsOrder[layer.geometryType]
                ]
            }
            selectedIdsOrder.highest = getHighestLayer(
                selectedIdsOrder[POINT_GEOMETRY_TYPE],
                selectedIdsOrder[LINE_GEOMETRY_TYPE],
                selectedIdsOrder[POLYGON_GEOMETRY_TYPE],
                selectedIdsOrder[RASTER_GEOMETRY_TYPE],
            )
            selectedIdsOrder.lowest = getLowestLayer(
                selectedIdsOrder[POINT_GEOMETRY_TYPE],
                selectedIdsOrder[LINE_GEOMETRY_TYPE],
                selectedIdsOrder[POLYGON_GEOMETRY_TYPE],
                selectedIdsOrder[RASTER_GEOMETRY_TYPE],
            )
            return {
                ...state,
                selectedIds: {...state.selectedIds, [payload.layerId]: true},
                selectedIdsOrder
            }
        }

        case types.DESELECT_LAYER: {
            if (!payload.layerId || (typeof payload.layerId !== "string" )){
                logError(`layersReducer > DESELECT_LAYER: payload.layerId is not a string - ${JSON.stringify(payload)}`)
                return state
            }
            deselectLayer(payload.layerId)
            return {
                ...state,
                selectedIds,
                selectedIdsOrder,
                loadedIds
            }
        }

        case types.SELECT_COUNTRY: {
            if (payload.multiSelect || !payload.lastSelectedId) return state
            if (payload.lastSelectedId === payload.countryId) return state
            const countryLayerIds = layersByCountryId[payload.lastSelectedId] ? layersByCountryId[payload.lastSelectedId] : []
            countryLayerIds.forEach(layerId => deselectLayer(layerId))
            return {
                ...state,
                selectedIds,
                selectedIdsOrder,
                loadedIds
            }
        }
        case types.DESELECT_COUNTRY:{
            if (!payload.countryId || (typeof payload.countryId !== "string" )){
                logError(`layersReducer > DESELECT_COUNTRY: payload.countryId is not a string - ${JSON.stringify(payload)}`)
                return state
            }

            const countryLayerIds = layersByCountryId[payload.countryId] ? layersByCountryId[payload.countryId] : []
            countryLayerIds.forEach(layerId => deselectLayer(layerId))
            return {
                ...state,
                selectedIds,
                selectedIdsOrder,
                loadedIds
            }
        } 

        case types.DESELECT_ALL_COUNTRIES: {
            return {
                ...state,
                selectedIds: {},
                selectedIdsOrder: initialState.layers.selectedIdsOrder,
                loadedIds: {}
            }
        }
        
        case types.TOGGLE_LAYER_LOADED: {
            if (!payload.layerId || (typeof payload.layerId !== "string" )){
                logError(`layersReducer > TOGGLE_LAYER_LOADED: payload.layerId is not a string - ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.loaded !== "boolean" ){
                logError(`layersReducer > TOGGLE_LAYER_LOADED: payload.loaded is not a boolean - ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                loadedIds: {
                    ...loadedIds,
                    [payload.layerId] : payload.loaded
                }
            }
        }
        case types.SAVE_STYLE:{
            if (!payload.layerId || (typeof payload.layerId !== "string" )){
                logError(`layersReducer > SAVE_STYLE: payload.layerId is not a string - ${JSON.stringify(payload)}`)
                return state
            }
            if (!payload.style || (typeof payload.style !== "object" )){
                logError(`layersReducer > SAVE_STYLE: payload.style is not an object - ${JSON.stringify(payload)}`)
                return state
            }
            layersById[payload.layerId] = {
                ...layersById[payload.layerId],
                style: payload.style
            }
            return {
                ...state,
                layersById
            }
        }

        case types.CHANGE_LAYER_ORDER: {
            if (!payload.layerId || (typeof payload.layerId !== "string" )){
                logError(`layersReducer > CHANGE_LAYER_ORDER: payload.layerId is not a string - ${JSON.stringify(payload)}`)
                return state
            }
            //if the layer is not selected return
            if (!selectedIds[payload.layerId]) return state
            layer = layersById[payload.layerId]
            //if the id does not correspond to a layer return
            if (!layer) return state
            const orderedIds = [...selectedIdsOrder[layer.geometryType]]
            //if the layer is the only layer of its type selected then it cannot be reordered
            if (orderedIds.length < 2) return state
            const currentIndex = orderedIds.findIndex(id => id === payload.layerId)
            if (currentIndex === -1) return state
            if (payload.moveUp) {
                //if moving up
                //if currently at the top, return
                if (currentIndex === 0) return state
                //otherwise move towards 0
                orderedIds[currentIndex] = orderedIds[currentIndex - 1]
                orderedIds[currentIndex - 1] = payload.layerId
            }  else {
                //if moving down
                //if currently at the bottom, return
                if (currentIndex === (orderedIds.length - 1 )) return state
                //otherwise move towards array end
                orderedIds[currentIndex] = orderedIds[currentIndex + 1]
                orderedIds[currentIndex + 1] = payload.layerId
            }
            selectedIdsOrder[layer.geometryType] = orderedIds
            //recalculated highest and lowest
            selectedIdsOrder.highest = getHighestLayer(
                selectedIdsOrder[POINT_GEOMETRY_TYPE],
                selectedIdsOrder[LINE_GEOMETRY_TYPE],
                selectedIdsOrder[POLYGON_GEOMETRY_TYPE],
                selectedIdsOrder[RASTER_GEOMETRY_TYPE],
            )
            selectedIdsOrder.lowest = getLowestLayer(
                selectedIdsOrder[POINT_GEOMETRY_TYPE],
                selectedIdsOrder[LINE_GEOMETRY_TYPE],
                selectedIdsOrder[POLYGON_GEOMETRY_TYPE],
                selectedIdsOrder[RASTER_GEOMETRY_TYPE],
            )
            return {
                ...state,
                selectedIdsOrder
            }
        }

        default: return state
    }
}

const sanitizeLayer = layer => {
    if(!(layer['dct:abstract'] && (typeof layer['dct:abstract'] === "string"))){     
        console.warn(`Layer ${layer['dc:source']} does not have a string as it's dct:abstract value. It's value ${layer['dct:abstract']} has been replaced `)
        layer['dct:abstract'] = ""
    }
    
    if(!(layer['dc:title'] && (typeof layer['dc:title'] === "string"))){
        console.warn(`Layer ${layer['dc:source']} does not have a string as it's dc:title value. It's value ${layer['dc:title']} has been replaced `)
        layer['dc:title'] = `No title for ${layer['dc:source']}`
    }
    if(!(layer['dc:date'] && (typeof layer['dc:date'] === "string"))){
        console.warn(`Layer ${layer['dc:source']} does not have a string as it's dc:date value. It's value ${layer['dc:date']} has been replaced `)
        layer['dc:date'] = ""
    }
    if(!(layer['dc:identifier'] && (typeof layer['dc:identifier'] === "string"))){
        console.warn(`Layer ${layer['dc:source']} does not have a string as it's dc:identifier value. It's value ${layer['dc:identifier']} has been replaced `)
        layer['dc:identifier'] = String(Date.now())
    }
    return layer
}
const formatLayer = layer => {
    if ((typeof layer !== 'object') || !layer['dc:source']){
        logError(`layersReducer > formatLayer: layer is not an object or is in the wrong format ${JSON.stringify(layer)}`)
        return null
    }
    else {
        //direct attributes
        const id = layer['dc:source']
        const description = layer['dct:abstract']
        const createdAt = layer['dc:date']
        const lastModifiedAt = layer['dct:modified']
        const title = layer['dc:title']
        const pycswId = layer['dc:identifier']

        //computed attributes
        const type = id.includes(ADMIN_STRING_IDENTIFIER) ? ADMIN_LEVEL_LAYER_TYPE : STANDARD_LAYER_TYPE
        const descriptors = id.split('_')
        const countryId = descriptors[0]
        let geometryType = ""
        let categoryId = ""
        if (!(descriptors.length > 1)) {
            logError(`layersReducer > formatLayer: id for layer ${id} is not properly formatted ${JSON.stringify(layer)}`)
        } 
        geometryType = descriptors[descriptors.length - 1]
        categoryId = descriptors[1]

        //data sources
        let geoJsonSource
        let tileJsonSource
        let wmsDataSource 
        let tmsDataSource
        let downloadSource

        const getDataSource = (reference) => {
            //if the scheme is in one of the allowed formats
                if (
                    dataSourceExtensionMap[reference['@scheme']] ||
                    reference['@scheme'] === HTML_LINK_SOURCE
                ){
                    if (reference['@scheme'] === HTML_LINK_SOURCE) return
                    if (!reference['#text']) {
                        logError("oh no!")
                        return
                    } 
                    const sourceFragments = String(reference['#text']).split(".")
                    if (sourceFragments[sourceFragments.length - 1] !== dataSourceExtensionMap[reference['@scheme']]){
                        logError("the link provided has the wrong extension", reference)
                    }
                    
                    switch(reference['@scheme']) {
                        case GEOJSON_DATA_SOURCE: geoJsonSource = reference['#text']; break;
                        case TILEJSON_DATA_SOURCE: tileJsonSource = {url: reference['#text'], sourceLayerId: id}; break;
                        case WMS_DATA_SOURCE: wmsDataSource = reference['#text']; break;
                        case TMS_DATA_SOURCE: tmsDataSource = reference['#text']; break;
                        case GEOPACKAGE_DOWNLOAD_SOURCE: downloadSource = reference['#text']; break;
                        default: break;
                    } 
                }
                else logError(`layersReducer > formatLayer: layer ${id} has a source link outside the allowed scheme ${JSON.stringify(layer)}`)
        }
        
        if (layer['dct:references']) {
            if (layer['dct:references'].length){
                //it is an array
                layer['dct:references'].forEach(reference => {
                    getDataSource(reference)
                })
            } else {
                getDataSource(layer['dct:references'])
            }
        } else {
            logError(`layersReducer > formatLayer: layer ${id} has no sources so it cannot be rendered ${JSON.stringify(layer)}`)
            return null
        }

        return ({
            id, 
            type,
            description,
            geometryType,
            pycswId,
            categoryId,
            countryId,
            title,
            createdAt,
            lastModifiedAt,
            geoJsonSource,
            tileJsonSource,
            wmsDataSource,
            tmsDataSource,
            downloadSource,
            style: null
        })
    }
}

const getHighestLayer = (
    pointLayer, 
    lineLayer,
    polygonLayer, 
    rasterLayer
) => pointLayer.length > 0 ?
            pointLayer[0] :
            lineLayer.length > 0 ?
            lineLayer[0] :
            polygonLayer.length > 0 ?
            polygonLayer[0] :
            rasterLayer.length > 0 ?
            rasterLayer[0] :
            null

const getLowestLayer = (
    pointLayer, 
    lineLayer,
    polygonLayer, 
    rasterLayer
) => rasterLayer.length > 0 ?
            rasterLayer[rasterLayer.length - 1] :
            polygonLayer.length > 0 ?
            polygonLayer[polygonLayer.length - 1] :
            lineLayer.length > 0 ?
            lineLayer[lineLayer.length - 1] :
            pointLayer.length > 0 ?
            pointLayer[pointLayer.length - 1] :
            null

export default layersReducer