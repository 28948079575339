import React, {Fragment} from "react"
import CloseButton from '../CloseButton'
 
import PropTypes from "prop-types"
import {logError} from "../../utils/errorHandlingUtils"
import styles from "./TagTray.module.css"

function TagTray({
        items = [], 
        displayProperty = 'name',
        onPressCloseTag=()=>{}
    }){
    const getTags = () => {
        try {
            return items.map(item => { 
                if (!(typeof item === 'object')) {
                    logError(new Error(`TagTray: the item ${JSON.stringify(item)} is not an object`))
                    return null
                }

                if (!item.id) {
                    logError(new Error(`TagTray: item ${JSON.stringify(item)} has no id`))
                    return null
                }
                
                const text = item[displayProperty] ? item[displayProperty] : item.id
                return <Tag
                            key={item.id} 
                            text={text}
                            onPressClose={() => onPressCloseTag(item.id)}
                        />
            })
        } catch(e){
            logError(e)
            return []
        }
    }
    
    return (
        <Fragment>
            {
            items.length > 0 ?
                <div className={styles.container}>{getTags()}</div>
            :
                null
            }
        </Fragment>
    )
}

TagTray.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })
    ),
    displayProperty: PropTypes.string,
    onPressCloseTag: PropTypes.func
}

export function Tag({text, onPressClose=()=>{}}){
    return (
        <div className={styles.tagContainer} >
            <span>{text}</span>
            <CloseButton onPress={onPressClose} theme="light"/>
        </div>
    )
}

Tag.propTypes = {
    text: PropTypes.string.isRequired,
    onPressClose: PropTypes.func
}
export default TagTray;