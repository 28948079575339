import React from 'react'
import Icon from '../Icon'

import PropTypes from 'prop-types'
import styles from './CheckBox.module.css'

function CheckBox({checked= false, onSelect=()=>{}, onDeselect=()=>{},label="", labelPosition=LABEL_POSITION_RIGHT, color}){
    const checkedStyle = checked ? styles.checked : null
    const onPress = checked ? onDeselect : onSelect
    const checkedColor = !color ? {} : checked ? {backgroundColor: color, borderColor: color} : {borderColor: color, backgroundColor:"var(--light-grey)"} 
    return (
        <div className={styles.container}>
            {labelPosition === LABEL_POSITION_LEFT ? <span>{label}</span> : null }
            <div 
                style={checkedColor}
                className={[styles.innerContainer, checkedStyle].join(" ")}
                onClick={() => onPress(!checked)}
            >
                {checked ? <Icon icon="check"/> : null }
            </div>
            {labelPosition === LABEL_POSITION_RIGHT ? <span>{label}</span> : null }
        </div>
    )
}

const LABEL_POSITION_RIGHT = 'right'
const LABEL_POSITION_LEFT = 'left'

CheckBox.propTypes = {
    checked: PropTypes.bool,
    onSelect:PropTypes.func,
    onDeselect:PropTypes.func,
    label: PropTypes.string,
    labelPosition: PropTypes.oneOf([LABEL_POSITION_LEFT, LABEL_POSITION_RIGHT]),
    color: PropTypes.string
}

export default CheckBox;