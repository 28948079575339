import React from 'react'
import PropTypes from 'prop-types'
import styles from './ActionPanel.module.css'

function ActionPanel({children}){
    return (
        <div className={[styles.container, 'raised', 'mapWidget'].join(" ")}>
            {children}
        </div>
    )
}


export default ActionPanel;

