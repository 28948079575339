import React from 'react'
import Badge from '../../components/Badge'

import {selectedEnvironment} from "../../config" 
import {DEVELOPMENT} from "../../constants/environment"

import styles from './Topbar.module.css'
import {connect} from "react-redux"
import PropTypes from "prop-types"

import support from "../../config/support"
import cdemaLogo from "./cdema-logo.png"
import crisLogo from "./cris-logo.png"
export function Topbar ({system}){
    const {version} = system
    console.log("Version v",version)
    return( 
        <div className={[styles.container, 'raised'].join(" ")}>
            <div className={styles.innerContainer}>
                <Badge label='Geocris' />
                {selectedEnvironment === DEVELOPMENT ? <Badge label={selectedEnvironment} /> : null}
                <a href="https://www.cdema.org/" target="_blank" rel="noopener noreferrer"><img className={styles.logo} src={cdemaLogo} /></a>
                <a href="https://www.cdema.org/cris/" target="_blank" rel="noopener noreferrer"><img className={styles.logo} src={crisLogo} /></a>
            </div>
            <div className={[styles.innerContainer, styles.right].join(" ")}>
                <a 
                    className={styles.externalLink} 
                    href="https://www.cdema.org/cris/index.php/partners-donors"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Partners and Donors
                </a>
                <a 
                    className={styles.externalLink} 
                    href={`mailto:${support.supportEmail}?subject=Issue With Geocris v${version}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    >Something Wrong? Tell Us
                </a>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    system: state.system
})

Topbar.propTypes = {
    system: PropTypes.shape({
        version: PropTypes.string.isRequired
    }).isRequired
}

export default connect(mapStateToProps)(Topbar);