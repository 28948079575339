/**
 *  Purpose: the modifications that are carried on the Country objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
// STATE
// countries:{
//   countriesById:{},
//   countryIds: [],
//   selectedIds: {},
//   lastSelectedId: null
// }


const countriesReducer = (state = initialState.countries, action) => {
    const {type, payload} = action;
    let countriesById = {...state.countriesById}
    let selectedIds = {...state.selectedIds}
    let lastSelectedId

    switch (type){

        case types.SAVE_COUNTRIES: {
            countriesById = Object.values(payload.countries).reduce((countriesById, country) => {
                country.downloadSource = `https://geocris2.cdema.org/downloads/${country.id}/${country.id}.gpkg`
                countriesById[country.id] = country
                return countriesById
            }, {})
            return {
                ...state,
                countriesById,
                countryIds: Object.keys(countriesById)
            }
        }

        case types.SELECT_COUNTRY: {
            if (!payload.countryId || (typeof payload.countryId !== "string" )){
                logError(`countriesReducer > SELECT_COUNTRY: payload.countryId is not a string - ${JSON.stringify(payload)}`)
                return state
            }
            selectedIds = payload.multiSelect ? {...state.selectedIds, [payload.countryId]: true} : {[payload.countryId]: true}
            return {
                ...state,
                selectedIds,
                lastSelectedId: payload.countryId
            }
        }

        case types.DESELECT_COUNTRY: {
            if (!payload.countryId || (typeof payload.countryId !== "string" )){
                logError(`countriesReducer > DESELECT_COUNTRY: payload.countryId is not a string - ${JSON.stringify(payload)}`)
                return state
            }
            const keys = Object.keys(selectedIds)
            //determine the new lastSelectedId
            //else keep the current lastSelectedId
            lastSelectedId = payload.countryId !== state.lastSelectedId ? 
                            state.lastSelectedId//if the deselected country id is not the last selected id, keep it as isl
                            :
                            keys.length > 1 ? //if it is not the only selected key
                            keys[keys.length - 2] //return the country selected before it
                            :
                            null // if it was the only selected country, return null
            
            delete selectedIds[payload.countryId]
            return {
                ...state,
                selectedIds,
                lastSelectedId
            }
        }

        case types.DESELECT_ALL_COUNTRIES: {
            return {
                ...state,
                selectedIds: {},
                lastSelectedId: null
            }
        }

        default: return state
    }
}

export default countriesReducer