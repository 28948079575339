import React from 'react'
import styles from './Sidebar.module.css'
import {connect} from "react-redux"
import PropTypes from "prop-types"

export function Sidebar ({user}){
    if (!user.authenticated) return null
    return <div className={[styles.container, 'raised'].join(" ")}></div>
}

Sidebar.propTypes = {
    user: PropTypes.shape({
        authenticated: PropTypes.bool.isRequired
    }).isRequired
}
const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Sidebar);