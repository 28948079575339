import React from 'react'
import PropTypes from 'prop-types'
import PointLayer from '../PointLayer'
import {Source, Layer} from "react-mapbox-gl";
import {stringToColor} from '../../utils/stringUtils'
import {logError} from "../../utils/errorHandlingUtils"
import {
    POINT_GEOMETRY_TYPE,
    LINE_GEOMETRY_TYPE,
    RASTER_GEOMETRY_TYPE,
    POLYGON_GEOMETRY_TYPE,
    GEOMETRY_TO_MAPBOX_LAYER_TYPE,
    FILL_MAPBOX_LAYER_TYPE,
    LINE_MAPBOX_LAYER_TYPE,
    SYMBOL_MAPBOX_LAYER_TYPE
} from "../../constants/mapBoxConstants"


import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

export class SelectedLayers extends React.Component {

    getLayers = () => {
        const {layers, actions, map} = this.props
        const pointOrder = layers.selectedIdsOrder[POINT_GEOMETRY_TYPE]
        const lineOrder = layers.selectedIdsOrder[LINE_GEOMETRY_TYPE]
        const polygonOrder = layers.selectedIdsOrder[POLYGON_GEOMETRY_TYPE]
        const rasterOrder = layers.selectedIdsOrder[RASTER_GEOMETRY_TYPE]
        const orderedLayers = [].concat(pointOrder).concat(lineOrder).concat(polygonOrder).concat(rasterOrder)
        return orderedLayers.map((layerId, i) => {
            //enforce ordering of layers, choose "before" as the next layer in order
            //unless this is the first layer 
            const before = i === 0 ?
                            null
                            :
                            orderedLayers[i - 1]
            let layer = layers.layersById[layerId]
            let source
            switch (layer.geometryType) {
                case POINT_GEOMETRY_TYPE: source = layer.geoJsonSource; break;
                case LINE_GEOMETRY_TYPE: source = layer.tileJsonSource.url; break;
                case POLYGON_GEOMETRY_TYPE: source = layer.tileJsonSource.url; break;
                case RASTER_GEOMETRY_TYPE: source = layer.tmsDataSource; break;
                default: break;
            }
            if (source) {
                return <SelectedLayer
                    key={layer.id}
                    loadStyle={()=> actions.fetchSaveStyle(layer.id)}
                    onLayerLoaded={()=>actions.toggleLayerLoaded(layer.id)}
                    style={layer.style}
                    layerId={layer.id}
                    geometryType={layer.geometryType}
                    source={source}
                    before={before}
                    map={map}
                />
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.getLayers()}
            </React.Fragment>
        )
    }
}

SelectedLayers.propTypes = {
    layers: PropTypes.shape({
        layersById: PropTypes.object.isRequired,
        layersByCountryId: PropTypes.object.isRequired,
        adminLevelsByCountryId: PropTypes.object.isRequired,
        layerIds: PropTypes.array.isRequired,
        selectedIds: PropTypes.object.isRequired
    })
}

class SelectedLayer extends React.Component{
    
    constructor(props){
        super(props)
        const {layerId, geometryType, source} = this.props
        const layerType = GEOMETRY_TO_MAPBOX_LAYER_TYPE[geometryType]
        if (!layerType) {
            logError(`SelectedLayers > SelectedLayer: The geometry type ${geometryType} is not supported. The layer will not render`)
            return null
        }
        if (!source) {
            logError(`SelectedLayers > SelectedLayer: The layer ${layerId} has no valid sources. The layer will not render`)
            return null
        }
        this.state = {
            layerType,
            color: stringToColor(layerId),
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        const {before, map, layerId, style} = nextProps
        /**
         * Purpose: the following code overrides the rendering of layers and manually rerenders them using map.moveLayer
         *        : this is necessary during reordering of 'layer' components made up of multiple layers, 
         *        : as the mapbox library does not properly reorder groups of layers 
         */
        
        //if before prop has changed
        if (before !== this.props.before) {
            const {layerType} = nextState
            //if layer type is a polygon or line
            if (layerType === LINE_MAPBOX_LAYER_TYPE || layerType === FILL_MAPBOX_LAYER_TYPE){
                //is there is no style
                if (!style) {
                    if (!before) map.moveLayer(layerId)
                    else map.moveLayer(layerId,before)
                } else {
                    // if there is a style loop through the style layers 
                    const layers = [...style.layers].reverse()
                    layers.forEach((layer,i) => {
                        const id = (layers.length - i) === 1 ?
                                    layerId
                                    : 
                                    `${layerId}-${layers.length - (i+1)}`
                                    
                        const paintBelow = i === 0 ?
                                            before
                                            :
                                            `${layerId}-${layers.length -1}`
                        if (!paintBelow) map.moveLayer(id)
                        else map.moveLayer(id, paintBelow)
                    })
                }
            
            //if if is a point layerr
            } else if (layerType === SYMBOL_MAPBOX_LAYER_TYPE) {
                //each point layer paints 4 layers
                [3,2,1,0].forEach((value, i) => {
                    if (i === 0){
                        if (!before) map.moveLayer(`${layerId}-${value}`)
                        else map.moveLayer(`${layerId}-${value}`,before)
                    } else map.moveLayer(value === 0 ? layerId : `${layerId}-${value}`,`${layerId}-${value + 1}`)
                })
            }
            return false
        }
        return true
    }

    componentDidMount = async () => {
        const {layerType} = this.state
        const {layerId, loadStyle, style, onLayerLoaded, map} = this.props
        onLayerLoaded()
        //if a style has already been loaded, don't load it again
        if (style) return
        //only do this for lines and polygons
        if (layerType !== FILL_MAPBOX_LAYER_TYPE && layerType !== LINE_MAPBOX_LAYER_TYPE) return
        loadStyle()
    }

    getPointLayer = () => {
        const {layerId, source, before} = this.props
        const {color} = this.state
        return <PointLayer
                    layerId={layerId}
                    sourceUrl={source}
                    before={before}
                color={color}/>
    }

    getRasterLayer = () => {
        const {layerId, source, before} = this.props
        const {layerType} = this.state
        const options = {
            "type": "raster",
            "tiles": [source],
            "tileSize": 512
        }
        return <React.Fragment>
                <Source
                    id={`${layerId}-source`}
                    tileJsonSource={options}/>
                <Layer
                    id={layerId}
                    sourceId={`${layerId}-source`}
                    sourceLayer={`${layerId}-source`}
                    type={layerType}
                    before={before}
                />
            </React.Fragment>
    }

    getVectorLayer = () => {
        const {layerId, source, style, before} = this.props
        const {layerType, color} = this.state
        const getLayer = () => {
            const paint = layerType === FILL_MAPBOX_LAYER_TYPE ?
                    {"fill-color": color}
                    :
                    layerType === LINE_MAPBOX_LAYER_TYPE ?
                    {"line-color": color}
                    :
                    {"fill-color": "#F27437"}
            return <Layer
                        id={layerId}
                        sourceId={`${layerId}-source`}
                        sourceLayer={layerId}
                        type={layerType}
                        paint={paint}
                        before={before}
                    />
        }
        const getStyledlayers = () => {
            const layers = [...style.layers].reverse()
            return layers.map((layer,i) => {
                const id = (layers.length - i) === 1 ?
                            layerId
                            : 
                            `${layerId}-${layers.length - (i+1)}`
                            
                const paintBelow = i === 0 ?
                                    before
                                    :
                                    `${layerId}-${layers.length -1}`
                return <Layer 
                        id={id}
                        key={id}
                        sourceId={`${layerId}-source`}
                        sourceLayer={layerId}
                        type={layer.type}
                        paint={layer.paint}
                        before={paintBelow}
       
                    />
            })
        }
        return (
            <React.Fragment>
                <Source
                    id={`${layerId}-source`}
                    tileJsonSource={{
                        type: "vector",
                        tiles: [source]
                    }}
                />
                {
                    style ? 
                    getStyledlayers()
                    :
                    getLayer()
                }
            </React.Fragment>
        )
    }
    render(){
        const {layerId, source, geometryType} = this.props
        const {layerType, color} = this.state
        // render layer per type
        return (
            <React.Fragment>
                {
                    (layerType === SYMBOL_MAPBOX_LAYER_TYPE) ?
                        this.getPointLayer()
                        :
                    (layerType === 'raster') ?
                        this.getRasterLayer()
                    :
                    this.getVectorLayer()  
                }
            </React.Fragment>
        )
        
    }
}
SelectedLayer.propTypes = {
    layerId: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    geometryType: PropTypes.oneOf([POINT_GEOMETRY_TYPE, LINE_GEOMETRY_TYPE, RASTER_GEOMETRY_TYPE, POLYGON_GEOMETRY_TYPE]).isRequired,
}

const mapStateToProps = state => ({
    layers: state.layers,
    map: state.map.mapbox
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
  

export default connect(mapStateToProps, mapDispatchToProps)(SelectedLayers);