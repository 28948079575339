import React from 'react';
import Sidebar from '../../containers/Sidebar'
import Topbar from '../../containers/Topbar'
import PropTypes from "prop-types"

import styles from './ScreenContent.module.css'

import {connect} from "react-redux"

class ScreenContent extends React.Component {
  
  render(){
    const {user} = this.props
    const sidebarHidden = user.authenticated ? null : styles.sidebarHidden
    return (
        <div className={[styles.container, sidebarHidden].join(" ")}>
          <div className={styles.header}>
          <Topbar/>
          </div>
          <Sidebar/>
          <div className={styles.content}>
            {this.props.children}
          </div>
        </div>
    );
  }
}

ScreenContent.propTypes = {
  children: PropTypes.node
}

const mapStateToProps = state => ({
  user: state.user
})

const ConnectedScreenContent = connect(mapStateToProps)(ScreenContent)

export function inScreenContent(WrappedComponent){
  return class extends React.Component {
    render () {
      return (
      <ConnectedScreenContent>
        <WrappedComponent {...this.props} />
      </ConnectedScreenContent>
      )
    }
  }
}
export default inScreenContent;
