import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import styles from './ResultList.module.css'
import {logError} from "../../utils/errorHandlingUtils"

function ResultList({
        resultList = [], 
        displayProperty='name', 
        onSelectResult=()=>{}, 
        onDeselectResult=()=>{}, 
        onChangeFocus=()=>{},
        selectedResults={}
    }){

    const getResults = () => {
        try {
            return resultList.map(result => { 
                if (!(typeof result === 'object')) {
                    logError(new Error(`ResultList: the result ${result} is not an object`))
                    return null
                }

                if (!result.id) {
                    logError(new Error(`ResultList: result ${JSON.stringify(result)} has no id`))
                    return null
                }
                const display = displayProperty ? result[displayProperty] : result
                const selected = Boolean(selectedResults[result.id])
                return <Result 
                            key={result.id} 
                            text={display}
                            onSelect={() => onSelectResult(result.id, display)}
                            onDeselect={() => onDeselectResult(result.id)}
                            selected={selected}
                        />
            })
        } catch(e){
            logError(e)
            return []
        }
    }
    const results = getResults()
    return (
        <Fragment>
        {
            (results && results.length > 0) 
            ?
            <div 
                className={styles.container} 
                onMouseEnter={() => onChangeFocus(true)}
                onMouseLeave={() => onChangeFocus(false)}
            >
                {results}
            </div>
            :
            null
        }
        </Fragment>
    )
}

ResultList.propTypes = {
    resultList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
      })).isRequired,
    displayProperty: PropTypes.string,
    onSelectResult: PropTypes.func,
    onDeselectResult: PropTypes.func,
    onChangeFocus: PropTypes.func,
    selectedResults: PropTypes.object
}

export function Result({text, onSelect=()=>{}, onDeselect=()=>{}, selected=false}){
    const selectedStyle = selected ? styles.resultSelected : styles.resultUnselected
    const onPress = selected ? onDeselect : onSelect
    return <div 
                onClick={onPress} 
                className={[styles.resultContainer, selectedStyle].join(" ")}
            >
                {text}
            </div>
}

Result.propTypes = {
    text: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    onDeselect:PropTypes.func,
    selected: PropTypes.bool
}

export default ResultList