import React from "react"
import Modal from 'react-modal'
import ActionPanelOption from "../../components/ActionPanelOption"
import DocumentSearch from "../DocumentSearch"
import styles from "./DocumentSearchPanelOption.module.css"
import Icon from "../../components/Icon"

class DocumentSearchPanelOption extends React.Component{
    
    state = {
        modalOpen: false,
        panelOpen: false
    }

    openModal = () => {
        this.setState({modalOpen: true}, ()=>setTimeout(() => this.setState({panelOpen: true}), 100))
    }

    closeModal = () => {
        this.setState({panelOpen: false}, ()=>setTimeout(() => this.setState({modalOpen: false}), 1000))
    }

    render(){
        const {modalOpen, panelOpen} = this.state
        const visibleStyle = panelOpen ? styles.visible : null
        return (
            <React.Fragment>
                <ActionPanelOption 
                    icon="search" 
                    label="lookup documents" 
                    onPress={this.openModal}
                    hasPopout={true}
                />
                {
                    modalOpen ?
                    <Modal
                    ariaHideApp={false}
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Document Search Modal"
                    className={['modalContent', styles.panel, visibleStyle].join(" ")}
                    overlayClassName={['overlay', styles.container].join(" ")}
                    >
                        <div className={styles.searchHeader}>
                            <div 
                                className={styles.backLink}
                                onClick={this.closeModal}
                            >
                                Back<Icon icon="keyboard-arrow-right"/>
                            </div>
                            <div className="title">Lookup Documents</div>
                        </div>
                        <DocumentSearch />
                    </Modal>
                    : null
                }
            </React.Fragment>
        )
    }
}


export default DocumentSearchPanelOption