/**
 * Purpose: Sentry remotely tracks runtime errors from our app
 * View Issues Dashboard here: https://sentry.io/organizations/cdema/issues/?project=5271781
 */
import * as Sentry from '@sentry/browser';
import {version} from "./release"
import {PRODUCTION} from "../constants/environment"
import {selectedEnvironment} from "./index"

import configureStore from "../store/configureStore"
import moment from "moment"

if (selectedEnvironment === PRODUCTION) {
    Sentry.init({
        dsn: "https://bb5b64007f1644db9fe00f209db0d7fc@o405671.ingest.sentry.io/5271781",
        release: version,
        environment: selectedEnvironment
    });
}

export const configureScope  = () => {
    const store = configureStore()
    Sentry.configureScope((scope) => {
        let {user, system} = store.getState()
        user = user.authenticated ? user : null
        scope.setUser({
            id: user ? user.id : "Not Logged In",
            username: user ? `${user.firstName} ${user.lastName}` : "No name available",
            email : user ? user.email : "No Email"
        });
        scope.setExtra("machineLocalTime", moment().format("MMM DD, YY h:mm A Z"));
        scope.setExtra("platform", system.name)
    });

}