import React from "react"
import styles from "./BackLink.module.css"
import Icon from "../../components/Icon"

function BackLink({onClick=()=>{}}){
    return <div 
                className={styles.container}
                onClick={onClick}
            >
                Back<Icon icon="keyboard-arrow-right"/>
            </div>
}
export default BackLink