import React from 'react'
import Icon from '../Icon'
import styles from './CloseButton.module.css'

import PropTypes from 'prop-types'

function CloseButton ({onPress=()=>{}, theme=THEME_DARK}) {
    const themeStyle = theme === THEME_DARK ? styles.darkTheme : null
    return <span 
                className={[styles.container, themeStyle].join(" ")} 
                onClick={onPress}>
                <Icon icon="close"/>
            </span>
}   

const THEME_DARK ="dark"
const THEME_LIGHT = "light"
CloseButton.propTypes = {
    onPress: PropTypes.func,
    theme: PropTypes.oneOf([THEME_DARK, THEME_LIGHT])
}
export default CloseButton;