import {CARIBBEAN_CENTER_COORDS} from "../constants/mapBoxConstants"
import {logError} from "./errorHandlingUtils"

export const calculateSubregionCenter = (lngLatList) => {

    /**
     * Source: https://gist.github.com/tlhunter/0ea604b77775b3e7d7d25ea0f70a23eb
     * Purpose: Calculates the center of multiple lnglat coordinates
     */
    if (typeof lngLatList !== "object") {
        return CARIBBEAN_CENTER_COORDS
    }
    if (lngLatList.length === 0) return CARIBBEAN_CENTER_COORDS

    let x = 0.0;
    let y = 0.0;
    let z = 0;

    lngLatList.forEach(coord => {
        let lat = coord.lat * Math.PI / 180;
        let lng = coord.lng * Math.PI / 180;

        x += Math.cos(lat) * Math.cos(lng);
        y += Math.cos(lat) * Math.sin(lng);
        z += Math.sin(lat);
    })

    x = x / lngLatList.length;
    y = y / lngLatList.length;
    z = z / lngLatList.length;

    let centralLng = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLat = Math.atan2(z, centralSquareRoot);

    return {
        lat: centralLat * 180 / Math.PI,
        lng: centralLng * 180 / Math.PI
    };
}

/**
 * Return merged extent from extent 1 and extent 2
 * @param extent1
 * @param extent2
 * @returns {*[]}
 */
export const mergeExtent = (extent1, extent2) => {
    return [
        extent1[0] < extent2[0] ? extent1[0] : extent2[0],
        extent1[1] < extent2[1] ? extent1[1] : extent2[1],
        extent1[2] > extent2[2] ? extent1[2] : extent2[2],
        extent1[3] > extent2[3] ? extent1[3] : extent2[3],
    ];
}

export const getTileJsonSource = (countryId, sourceLayerId) => {
    if (!countryId || !sourceLayerId){
        logError(`mapUtils > getTileJsonSource: function called without necessary parameters countryId ${countryId} source layer id ${sourceLayerId}`)
        return
    }
    return {
        url: `https://geocris2.cdema.org/tegola/maps/${countryId}/${sourceLayerId}/{z}/{x}/{y}.pbf`,
        sourceLayerId
    }
}