import React from "react"
import {SYMBOL_MAPBOX_LAYER_TYPE} from "../../constants/mapBoxConstants"
import PropTypes from "prop-types"
import { Source, Layer, Image} from "react-mapbox-gl";
import shadowImg from "./marker-shadow.png"
import {logError} from "../../utils/errorHandlingUtils";

class PointLayer extends React.Component{
    /**
     * Purpose: displays point layers and handles popup logic
     */
    state = {
        geojson: {
            "type": "FeatureCollection",
            "features": []
            }
    }

    componentDidMount(){
        const {sourceUrl} = this.props
        fetch(`${sourceUrl}?limit=10000`)
            .then(res => res.json()).then(
                geojson => {
                    this.setState({geojson})
                }
            )
            .catch(err => {
                logError(`CountrySelectionLayer > fetch:`, err)
            })
    }

    componentWillUnmount() {
        this.hideComponents = true
    }
    render(){
        const {geojson} = this.state
        const {layerId, sourceUrl, color, before} = this.props
        const imageId = `${layerId}-image`
        const imageHaloId = `${layerId}-halo`
        const shadowId = `${layerId}-shadow`
        const colorCode = color.substr(1)
        const haloImageLink = `https://img.icons8.com/material/45/ffffff/marker--v1.png`
        const iconImageLink = `https://img.icons8.com/material/39/${colorCode}/marker--v1.png`
        return (
            <React.Fragment>
                {
                    !this.hideComponents //hacky fix to avoid mapbox's error message when the components are unmounted see: ComponentWillUnmount
                    ?
                    <React.Fragment>
                        <Image
                            id={imageHaloId}
                            url={haloImageLink}
                        />
                        <Image
                            id={imageId}
                            url={iconImageLink}
                        />
                        <Image
                            id={shadowId}
                            url={shadowImg}
                        />
                        <Source
                            id={`${layerId}-source`}
                            geoJsonSource={{type: "geojson", data: geojson}}
                            />
                        <Layer
                            id={`${layerId}-3`}
                            sourceId={`${layerId}-source`}
                            type={SYMBOL_MAPBOX_LAYER_TYPE}
                            paint={{
                                'text-halo-color': 'white',
                                'text-halo-width': 2
                            }}
                            layout={{
                                "text-allow-overlap":false,
                                "text-anchor": "left",
                                "icon-size": 0.8,
                                "text-field" : "{name}",
                                "text-size": 14,
                                "text-offset": [1, 0]
                            }} 
                            before={before}
                        />
                        <Layer
                            id={`${layerId}-2`}
                            sourceId={`${layerId}-source`}
                            type={SYMBOL_MAPBOX_LAYER_TYPE}
                            layout={{
                                    "icon-image": imageId,
                                    "icon-allow-overlap": true,
                                    "icon-size": 1,
                                }}
                            before={`${layerId}-3`}
                        />
                        <Layer
                            id={`${layerId}-1`}
                            sourceId={`${layerId}-source`}
                            type={SYMBOL_MAPBOX_LAYER_TYPE}
                            layout={{
                                    "icon-image": imageHaloId,
                                    "icon-allow-overlap": true,
                                    "icon-size": 1,
                                }}
                            before={`${layerId}-2`}
                        />
                        <Layer
                            id={layerId}
                            sourceId={`${layerId}-source`}
                            type={SYMBOL_MAPBOX_LAYER_TYPE}
                            layout={{
                                    "icon-image": shadowId,
                                    "icon-allow-overlap": true,
                                    "icon-size": 1,
                                    "icon-offset": [9, -4],
                                }}
                            before={`${layerId}-1`}
                        />




                </React.Fragment>
                :
                null
                }
            </React.Fragment>
        )
    }
}

PointLayer.propTypes = {
    layerId: PropTypes.string.isRequired,
    sourceUrl: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
}

export default PointLayer
