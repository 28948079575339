import React from 'react';
import MainMap from '../../containers/MainMap';
import MapControls from '../../containers/MapControls'
import Legend from '../../containers/Legend'
import ActionPanel from '../../containers/ActionPanel'
import MobileNavigationBar from "../../containers/MobileNavigationBar"

import DocumentSearchPanelOption from '../../containers/DocumentSearchPanelOption'
import DownloadMapPanelOption from '../../containers/DownloadMapPanelOption'
import ShareMapPanelOption from '../../containers/ShareMapPanelOption'
import styles from './HomeMap.module.css'

import inScreenContent from "../../hoc/inScreenContent"

import {Route, BrowserRouter} from 'react-router-dom'
import GeoCrisRouter from '../../router/router'

class HomeMap extends React.Component{

  constructor(props){
    super(props)
    const multiSelectCountries = this.props.match.params.countries ? this.props.match.params.countries.split(',').length > 1 : false
    const {latitude, longitude, zoom} = this.initiateMapPosition()
    this.state = {
      multiSelectCountries,
      latitude,
      longitude,
      zoom
    }
  }

  toggleMultiSelectCountries = (multiSelect = null) => {
    const multiSelectCountries = multiSelect ? multiSelect : !this.state.multiSelectCountries
    this.setState({multiSelectCountries})
  }

  initiateMapPosition = () => {
    let latitude, longitude = null
    let zoom = null
    if (this.props.location.hash) {
        let locations = this.props.location.hash.replace('#', '').split('/')
        if (locations.length === 3
            && !isNaN(locations[0])
            && !isNaN(locations[1])
            && !isNaN(locations[2])) {
            latitude = parseFloat(locations[0])
            longitude = parseFloat(locations[1])
            zoom = parseFloat(locations[2])
        }
    }
    return {latitude, longitude, zoom}
  }
  render(){
    const {multiSelectCountries, latitude, longitude, zoom} = this.state

    return (
        <div className={styles.container}>
          <BrowserRouter>
            <Route path="/:countries?/:layers?" component={GeoCrisRouter}/>
          </BrowserRouter>

          <MapControls
            multiSelectCountries={multiSelectCountries}
            onToggleMultiSelectCountries={this.toggleMultiSelectCountries}
          />
          <ActionPanel >
              <DocumentSearchPanelOption />
              <ShareMapPanelOption />
              <DownloadMapPanelOption />
          </ActionPanel>
          <Legend />
          
          <MainMap
              multiSelectCountries={multiSelectCountries}
              latitude={latitude}
              longitude={longitude}
              zoom={zoom}
          >
          </MainMap>
          <MobileNavigationBar />
        </div>
    );
  }
}

export default inScreenContent(HomeMap);
