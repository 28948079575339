import React from "react"
import Modal from 'react-modal'
import ActionPanelOption from "../../components/ActionPanelOption"
import BackLink from "../../components/BackLink"
import styles from './ShareMapPanelOption.module.css'
import {copyToClipboard} from "../../utils/interactionUtils"

import {withRouter} from "react-router-dom"
import ReactTooltip from "react-tooltip";

class ShareMapPanelOption extends React.Component{

    state = {
        modalOpen: false,
    }

    openModal = () => this.setState({modalOpen: true})

    closeModal = () => this.setState({modalOpen: false})

    render(){
        const {modalOpen} = this.state
        
        return (
            <React.Fragment>
                <ActionPanelOption 
                    icon="share" 
                    label="share map" 
                    onPress={this.openModal}
                />
                {
                    modalOpen ?
                    <Modal
                    ariaHideApp={false}
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Download Map Modal"
                    className={['modalContent', styles.content].join(" ")}
                    overlayClassName={['overlay', styles.container].join(" ")}
                    >
                        <div className="title">Share Geocris Link</div>
                        <ShareMap />
                        <div className={styles.buttonContainer}>
                            <button className="button" onClick={this.closeModal}>Done</button>
                        </div>
                    </Modal>
                    : null
                }
            </React.Fragment>
        )
    }
}

export class ShareMap extends React.Component {

    constructor(props){
        super(props)
        this.copyButton = React.createRef()
    }

    handleTooltip = () => {
        const node = this.copyButton
        ReactTooltip.show(node);
        setTimeout(() => {
            ReactTooltip.hide(node);
        }, 1500);
    };

    render(){
        const currentUrl = window.location.href
        return(
                <div className={styles.urlContainer}>
                    <div className={styles.copyField}>
                    <input 
                        type="text"
                        value={currentUrl}
                        onChange={()=>{}}
                    />
                    <button 
                        ref={ref => this.copyButton = ref}
                        onClick={() => {
                            copyToClipboard(currentUrl)
                            this.handleTooltip() 
                        }} 
                        data-tip="copied!" 
                    >
                            Copy
                    </button>
                    <ReactTooltip event="no-event" />
                    </div>
                </div>
        )
    }
} 
export default withRouter(ShareMapPanelOption)