import React from 'react'
import { Source, Layer } from "react-mapbox-gl";

import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

import {logError} from "../../utils/errorHandlingUtils"

class CountrySelectionLayer extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            hasHover: false,
            geojson: null
        }
        this.enteredOverlay = false //checks whether of not an event for the overlaid hover fill has fired
    }
    
    static defaultProps = {
        multiSelect: false
    }
    componentDidMount(){
        const{layers, countries, countryId} = this.props
        const country = countries.countriesById[countryId]
        let countryLayer = null
        if(layers.adminLevelsByCountryId[countryId]){
            for(let i = 0; i <= 3; i++){
                if (layers.layersById[layers.adminLevelsByCountryId[countryId][i]]){
                    countryLayer = layers.layersById[layers.adminLevelsByCountryId[countryId][i]]
                    break;
                } 
            }
        }

        if (!countryLayer) {
            logError(`CountrySelectionLayer: ${country.name} does not have any admin boundaries`)
            return null
        }
        fetch(countryLayer.geoJsonSource)
            .then(res => res.json()).then(
                geojson => {
                    this.setState({geojson})
                }
            )
            .catch(err => {
                logError(`CountrySelectionLayer > fetch:`, err)
            })
    }

    toggleHover = hasHover => {
        if (!hasHover) this.enteredOverlay = false
        else setTimeout(() => {if (!this.enteredOverlay && this.state.hasHover) {this.toggleHover(false)}}, 500)
        if (hasHover !== this.state.hasHover){
            this.setState({hasHover})
        }
    }
    
    selectCountry = () => {
        const {countryId, actions, multiSelect, countries} = this.props
        if (countries.selectedIds[countryId]) return //dont select an already selected country
        actions.selectCountry(countryId, multiSelect, countries.lastSelectedId)
    }
    render(){
        const {countryId, countries, layers} = this.props
        const {hasHover, geojson} = this.state
        const sourceId = `${countryId}-country-selection-layer`
        const selected = Boolean(countries.selectedIds[countryId])
        const before = layers.loadedIds[layers.selectedIdsOrder.lowest] ?
                            layers.selectedIdsOrder.lowest
                            :
                            null
        return (
            <React.Fragment>
                {
                    geojson ? 
                        <React.Fragment>
                            <Source id={sourceId} geoJsonSource={{ type: "geojson", data: geojson}} />
                            <Layer
                                sourceId={sourceId}
                                type="fill"
                                paint={{
                                    "fill-color": "#F27437",
                                    "fill-opacity": 0
                                }}
                                onMouseEnter={() => {this.toggleHover(true)}}
                                onMouseLeave={() => {this.toggleHover(false)}}
                                onClick={this.selectCountry}
                                before={before}
                            />
                            {
                                selected ?
                                    <Layer
                                        sourceId={sourceId}
                                        type="line"
                                        paint={{
                                        "line-color": "#F27437",
                                        "line-width": 3
                                        }}
                                        before={before}
                                    />
                                :
                                null
                            }   
                            {

                                hasHover && !selected?
                                    <Layer
                                        sourceId={sourceId}
                                        type="fill"
                                        paint={{
                                            "fill-color": "#F27437",
                                            "fill-opacity": 0.2
                                        }}
                                        onMouseEnter={() => {this.enteredOverlay = true}}
                                        onMouseLeave={() => {this.toggleHover(false)}}
                                        onClick={this.selectCountry}
                                    />
                                :
                                null
                            }
                        </React.Fragment>
                    :
                    null
                }
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => ({
    layers: state.layers,
    countries: state.countries
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  });

CountrySelectionLayer.propTypes = {
    countryId: PropTypes.string.isRequired,
    layers: PropTypes.shape({
        layersById: PropTypes.object.isRequired,
        layersByCountryId: PropTypes.object.isRequired,
        adminLevelsByCountryId: PropTypes.object.isRequired,
        layerIds: PropTypes.array.isRequired,
        selectedIds: PropTypes.object.isRequired
    }).isRequired,
    countries: PropTypes.shape({
        countriesById: PropTypes.object.isRequired,
        countryIds: PropTypes.array.isRequired,
        selectedIds: PropTypes.object.isRequired,
        lastSelectedId: PropTypes.string
    }).isRequired,
    actions: PropTypes.shape({
        selectCountry: PropTypes.func.isRequired
    }).isRequired,
    multiSelect: PropTypes.bool
}
export default connect(mapStateToProps, mapDispatchToProps)(CountrySelectionLayer);