import React from 'react'
import SearchField from '../SearchField'
import ResultList from '../ResultList'
import PropTypes from 'prop-types'
import styles from "./SearchDropdown.module.css"

class SearchDropdown extends React.Component{
    
    static defaultProps = {
        placeholder: '', 
        onChangeText: () => {}, 
        results: [], 
        selectedResults: {},
        onSelectResult: ()=>{},
        onDeselectResult: ()=>{},
        onDeselectAll:()=>{},
        displayUnfilteredListOnEmptySearchFieldFocus: false,
        unfilteredOptions: [],
        multiSelect: false,
        searchText: ''
    }

    componentDidUpdate(prevProps, prevState) {
        //if there has been a change in focus in the result list or search field
        if (
            (prevState.resultListHasFocus !== this.state.resultListHasFocus) ||
            (prevState.searchFieldHasFocus !== this.state.searchFieldHasFocus)
        ){
            const {displayUnfilteredListOnEmptySearchFieldFocus} = this.props
            const {searchText, resultListHasFocus, searchFieldHasFocus} = this.state
            if (!searchFieldHasFocus && !resultListHasFocus) this.setState({displayUnfilteredList: false})
            else if (displayUnfilteredListOnEmptySearchFieldFocus && !(searchText.trim())) this.setState({displayUnfilteredList: true})
            else this.setState({displayUnfilteredList: false})
        }

        const prevSelectedResults = Object.keys(prevProps.selectedResults)
        const selectedResults = Object.keys(this.props.selectedResults)
        if (
            !this.props.multiSelect && 
            selectedResults.length > 0 &&
            (
                (prevSelectedResults.length === 0) ||
                (prevSelectedResults[0] !== selectedResults[0])
            )
        ){
            const selected = this.props.unfilteredOptions.find(option => option.id === selectedResults[0])
            this.setState({searchText: selected.name, resultListHasFocus: false}, () => this.props.onChangeText(selected.name))
        }

        //if the user first selects an option (country) from the results list, them turns on multiselect
        //in this case, clear the search bar once the multi-select mode is activated
        if ((this.props.multiSelect !== prevProps.multiSelect) && this.props.multiSelect){
            this.setState({searchText: ""}, () => this.props.onChangeText(""))
        }
    }

    state = {
        displayUnfilteredList: false,
        searchText: this.props.searchText,
        resultListHasFocus: false,
        searchFieldHasFocus: false
    }

    handleSearchFieldChangeText = searchText => {
        const {onChangeText} = this.props
        const displayUnfilteredList = !searchText.trim()
        this.setState({searchText, displayUnfilteredList}, () => onChangeText(searchText))
    }

    handleSearchFieldChangeFocus = searchFieldHasFocus => this.setState({searchFieldHasFocus})

    handleResultListChangeFocus = resultListHasFocus => {
        this.setState({resultListHasFocus})
    }

    handleSelectResult = (id) => {
        const {onSelectResult, multiSelect} = this.props
        onSelectResult(id)
        if (multiSelect) this.handleSearchFieldChangeText("")
    }



    render(){
        const {
            placeholder, 
            results, 
            onDeselectResult,
            onDeselectAll,
            resultDisplayProperty,
            unfilteredOptions,
            selectedResults,
            multiSelect
        } = this.props
        const {displayUnfilteredList, searchText} = this.state
        //display the results relevant to the search text if there is one or more result
        //if there are no search results and the displayUnfilteredList flag is true, display the unfiltered list
        let resultList = results.length > 0 ? results : displayUnfilteredList ? unfilteredOptions : []  
        resultList = ((results.length === 1) && !multiSelect && (searchText === results[0].name)) ? [] : resultList
        return (
            <div className={styles.container}>
                <SearchField 
                    text={searchText}
                    placeholder={placeholder}
                    onChangeText={this.handleSearchFieldChangeText}
                    onChangeFocus={this.handleSearchFieldChangeFocus}
                    onPressClose={onDeselectAll}
                />
                <ResultList 
                    resultList={resultList} 
                    displayProperty={resultDisplayProperty}
                    onSelectResult={this.handleSelectResult}
                    onDeselectResult={onDeselectResult}
                    onChangeFocus={this.handleResultListChangeFocus}
                    selectedResults={selectedResults}
                />   
            </div>
        )
    }
}

SearchDropdown.propTypes = {
    placeholder: PropTypes.string,
    onChangeText : PropTypes.func,
    results: PropTypes.array,
    selectedResults: PropTypes.object,
    onSelectResult: PropTypes.func,
    onDeselectResult: PropTypes.func,
    onDeselectAll: PropTypes.func,
    resultDisplayProperty: PropTypes.string,
    displayUnfilteredListOnEmptySearchFieldFocus: PropTypes.bool,
    unfilteredOptions: PropTypes.array,
    multiSelect: PropTypes.bool
}

export default SearchDropdown