import React from 'react'
import Icon from "../Icon"

import styles from './SortButton.module.css'

export default ({
        onClickUp=()=>{},
        onClickDown=()=>{},
        highlight=null
    }) => {
        
    const topStyle = highlight === "top"?  styles.hidden : null 
    const bottomStyle = highlight === "bottom" ? styles.hidden : null
    return (
        <span className={styles.container}>   
            <span onClick={onClickUp} className={[styles.top, topStyle].join(" ")}><Icon icon="arrow-drop-up"/></span>
            <span onClick={onClickDown} className={[styles.bottom, bottomStyle].join(" ")}><Icon icon="arrow-drop-down"/></span>
        </span>
    )
}