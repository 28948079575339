import React from "react"
import Modal from 'react-modal'
import ActionPanelOption from "../../components/ActionPanelOption"
import BackLink from "../../components/BackLink"

import styles from "./DownloadMapPanelOption.module.css"

import * as jspdf from 'jspdf'
import * as html2canvas from 'html2canvas'

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

class DownloadMapPanelOption extends React.Component{
    
    state = {
        modalOpen: false,
    }


    download = async () => {
        const {map} = this.props
        //get elements from the map
        const mapCanvas = map.getCanvas()
        //create pdf
        var height = mapCanvas.offsetHeight
        var width = mapCanvas.offsetWidth

        //create a pdf with the same dimensions as the screen
        var pdf = new jspdf({
            orientation: 'landscape',
            unit: 'px',
            format: [width, height]
        });
        //jspdf still changes these dimensions, so calculate a mapping, so the image takes up all available space
        const conversionRatio = pdf.internal.pageSize.width/width
        //add an image, with it's size adjusted by the ratio/mapping
        pdf.addImage(
            mapCanvas.toDataURL(),
            'PNG', 0, 0, (width * conversionRatio), (height * conversionRatio)
        );
        pdf.save('download.pdf');
    }

    openModal = () => this.setState({modalOpen: true})

    closeModal = () => this.setState({modalOpen: false})

    render(){
        const {modalOpen} = this.state
        const {map} = this.props
        return (
            <React.Fragment>
                <ActionPanelOption 
                    icon="picture-as-pdf" 
                    label="export map to pdf" 
                    onPress={this.openModal}
                />
                {
                    modalOpen ?
                    <Modal
                    ariaHideApp={false}
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Download Map Modal"
                    className={['modalContent', styles.content].join(" ")}
                    overlayClassName={['overlay', styles.container].join(" ")}
                    >
                            <div className={styles.linkContainer}>
                                <BackLink onClick={this.closeModal} />
                            </div>
                            <div className={styles.previewContainer}>
                            {
                                map ?
                               
                                    <img src={map.getCanvas().toDataURL()}/>
                               
                                :
                                null
                            }
                        
                            </div>
                            <div className={styles.buttonContainer}>
                                <button className="button" onClick={this.download}>download</button>
                            </div>
                    </Modal>
                    : null
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    map: state.map.mapbox
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadMapPanelOption)