import React from "react"
import styles from "./ActionPanelOption.module.css"
import PropTypes from "prop-types"

import Icon from "../../components/Icon"
import {featureNotYetImplementedPopup} from '../../utils/developmentUtils'

function ActionPanelOption({icon="", label="action option", onPress=featureNotYetImplementedPopup, hasPopout=false}){
    const popoutStyle = hasPopout? {visibility: 'visible'} : {visibility: 'hidden'}
    return (
        <div className={styles.container} onClick={onPress}>
                <Icon customStyles={popoutStyle} icon="keyboard-arrow-left"/>
                <Icon icon={icon}/>
                <span>{label}</span>
        </div>
    )
}

ActionPanelOption.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    onPress: PropTypes.func,
    hasPopout: PropTypes.bool
}

export default ActionPanelOption;