import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

import styles from "./MenuDropdown.module.css"

class MenuDropdown extends React.Component{

    static defaultProps = {
        heading:"", 
        collapsed: true, 
        menuItems:[]
    }

    constructor(props){
        super(props)
        this.state = {
            collapsed: props.collapsed
        }
    }

    toggleCollapsed = () => this.setState({collapsed: !this.state.collapsed}) 
    
    render(){
        const {heading, menuItems} = this.props
        const {collapsed} = this.state
        const icon = collapsed ? "keyboard-arrow-right" : "keyboard-arrow-down"
        return (
            <div className={styles.container}>
                <div 
                    className={styles.heading}
                    onClick={this.toggleCollapsed}
                >
                    <Icon icon={icon}/>
                    {heading}
                </div>
                {
                    !collapsed ?
                    <div className={styles.collapsable}>
                        {menuItems}
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

MenuDropdown.propTypes = {
    heading: PropTypes.string,
    collapsed: PropTypes.bool,
    menuItems:PropTypes.array
}

export default MenuDropdown;