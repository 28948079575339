import React from "react"
import PropTypes from "prop-types"
import styles from "./Legend.module.css"

import Icon from "../../components/Icon"
import SortButton from "../../components/SortButton"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

import {compareStrings, stringToColor} from "../../utils/stringUtils"

import {
    POINT_GEOMETRY_TYPE,
    LINE_GEOMETRY_TYPE,
    RASTER_GEOMETRY_TYPE,
    POLYGON_GEOMETRY_TYPE
} from "../../constants/mapBoxConstants"

export function Legend({layers, actions}){
    const {selectedIdsOrder} = layers
    const orderedLayerIds = [].concat(selectedIdsOrder[POINT_GEOMETRY_TYPE])
                              .concat(selectedIdsOrder[LINE_GEOMETRY_TYPE])
                              .concat(selectedIdsOrder[POLYGON_GEOMETRY_TYPE])
                              .concat(selectedIdsOrder[RASTER_GEOMETRY_TYPE])
    if (orderedLayerIds.length === 0) return null
    return(
        <div className={[styles.container, 'raised', 'mapWidget'].join(" ")}>
            <div className={styles.title}>Legend</div>
            {
                orderedLayerIds.map(layerId => {
                    const layer = layers.layersById[layerId]
                    return <LegendOption 
                                key={layerId}
                                name={layer.title}
                                type={layer.geometryType}
                                style={layer.style}
                                id={layerId}
                                displaySort={selectedIdsOrder[layer.geometryType].length > 1}
                                isHighest={selectedIdsOrder[layer.geometryType][0] === layerId}
                                isLowest={selectedIdsOrder[layer.geometryType][selectedIdsOrder[layer.geometryType].length - 1] === layerId}
                                onMoveUp={() => actions.changeLayerOrder(layerId)}
                                onMoveDown={() => actions.changeLayerOrder(layerId, false)}
                            />
                })
            }
        </div>
    )
}

function LegendOption ({name, type, style, id, onMoveUp=()=>{}, onMoveDown=()=>{}, displaySort=false, isHighest=false, isLowest=false}){
    let customStyles 
    if (!style  || !style.layers || style.layers.length === 0){
        customStyles = type === POINT_GEOMETRY_TYPE ?
                        {"color": stringToColor(id)}
                        :
                        type === POLYGON_GEOMETRY_TYPE || type === LINE_GEOMETRY_TYPE ?
                        {"backgroundColor": stringToColor(id)}
                        :
                        {}
    } else {
        customStyles = {}
        style.layers.forEach( layer => {
            if (type === LINE_GEOMETRY_TYPE){
                if (layer.paint['line-color']) customStyles.backgroundColor = layer.paint['line-color']
            } else if (type === POLYGON_GEOMETRY_TYPE){
                if (layer.paint['fill-color']) customStyles.backgroundColor = layer.paint['fill-color']
                if (layer.paint['line-color']) customStyles.border = `2px solid ${layer.paint['line-color']}`
            }
        })
                       
    }

    if (type === RASTER_GEOMETRY_TYPE) {
        const url = 'https://geocris2.cdema.org/mapproxy/service?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=100&HEIGHT=100&sld_version=1.1.0&LAYER='+id
        return (
            <div className={styles.imgContainer}>
                <img src={url}/>
                <span>{name}</span>
            </div>
        )
    }

    const symbol = type === POINT_GEOMETRY_TYPE ?
                    <Icon icon="place" customStyles={customStyles}/>
                    :
                    type === POLYGON_GEOMETRY_TYPE?
                    <div style={customStyles} className={styles.polygonSymbol} />
                    :
                    type === LINE_GEOMETRY_TYPE?
                    <div style={customStyles} className={styles.lineSymbol} />
                    :
                    null

    const position = isHighest ? "top" : isLowest ? "bottom" : null
    return (
        <div className={styles.optionContainer}>
            <div className={styles.optionLabel}>
                <div className={styles.symbolContainer}>
                    {symbol}
                </div>
                <span>{name}</span>
            </div>
            <div>
                {displaySort ?<SortButton onClickUp={onMoveUp} onClickDown={onMoveDown} highlight={position}/> : <div className={styles.sortSpacer}/>}
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    layers: state.layers
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Legend)