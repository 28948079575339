import React, {PureComponent} from 'react'
import PropTypes from 'prop-types';
import ReactMapboxGl from "react-mapbox-gl";
import {MAP_DEFAULT_ZOOM_LEVELS, CARIBBEAN_CENTER_COORDS} from "../../constants/mapBoxConstants"
import {DEVICE_LAPTOP} from "../../constants/deviceConstants"
const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapboxMap = ReactMapboxGl({
    accessToken,
    doubleClickZoom: true,
    preserveDrawingBuffer: true
});

class Map extends PureComponent {
    /**
     * Purpose: A basic configuration of mapbox to display a map, this is used within bigger map components like DisplayMap and InteractiveMap
     *
     * Usage Notes:
     *  1. This map takes the size of its containing element, therefore it must be used within an element with a height & width
     *      or one that inherits. These properties from another element.
     *      example:
     *          width: 100%;
                height: 100vh;

     *      NOTE: If used within an element with no height and/or width property, nothing will be displayed
     *  2. This map displays nothing if a latitude and longitude are not provided
     *
     *  3. Change the 'zoom' property in defaultProps to toggle the default zoom setting.
     *      NOTE: Lower numbers are further away from the earth's surface
     */

    constructor(props){
        super(props)
        this.zoom = props.zoom
        this.latitude = props.latitude
        this.longitude = props.longitude
        this.extent = props.extent
        this.center = [props.longitude, props.latitude]
    }

    static defaultProps = {
        zoom: MAP_DEFAULT_ZOOM_LEVELS[DEVICE_LAPTOP],
        latitude: CARIBBEAN_CENTER_COORDS.latitude,
        longitude: CARIBBEAN_CENTER_COORDS.longitude,
        mapStyle : {
            height: '100%',
            width: '100%'
        },
        baseMap: "mapbox://styles/mapbox/streets-v9"
    }

    UNSAFE_componentWillUpdate(nextProps){
        const {latitude, longitude} = this.props
        if ((nextProps.latitude !== latitude) && (nextProps.longitude !== longitude)){
            this.zoom = nextProps.zoom
            this.latitude = nextProps.latitude
            this.longitude = nextProps.longitude
            this.center = ((typeof this.longitude === "number") && (typeof this.latitude === "number")) ? [this.longitude, this.latitude] : null
        }
        this.extent = nextProps.extent
    }

    filterBasemapFeatures = (features) => {
        /** remove features from the basemap **/
        return features.filter(
            feature => (feature.source !== 'composite')
        )
    }

    handleClick = (map,e) => {
        if (this.props.onClick){
            const features = map.queryRenderedFeatures(e.point);
            this.props.onClick(e.lngLat, this.filterBasemapFeatures(features), e.originalEvent.ctrlKey)
        }
    }

    handleDoubleClick = (map, e) => {
        if (this.props.onDoubleClick){
            const features = map.queryRenderedFeatures(e.point);
            this.props.onDoubleClick(e.lngLat, this.filterBasemapFeatures(features), e.originalEvent.ctrlKey)
        }
    }

    handleHover = (map, e) => {
        /**
         * Purpose: Pass the features on the hovered over point to the onHover function
         */
        let features = map.queryRenderedFeatures(e.point);
        features = this.filterBasemapFeatures(features)
        if (this.props.onHover){
            this.props.onHover(e.lngLat, this.filterBasemapFeatures(features))
        }
        // change cursor when there is hovered features
        map.getCanvas().style.cursor = features.length ? 'pointer' : '';
    }

    handleZoom = (map) => {
        this.zoom = map.getZoom()
    }

    handleZoomStart = (map, e) => {
        if (e.originalEvent && e.originalEvent.shiftKey) {
            //store the zoomIn handler in a variable
            this.wheelHandler = map.scrollZoom.onWheel
            this.scrollCount = 0
            // change the onwheel handler from the zoom function to our custom function
            map.scrollZoom.onWheel = (e)=> {
                //on every iteration remove the previous timeout
                clearTimeout(this.wheelRestore)
                if (e.shiftKey){
                    //if the shiftkey is still held down
                    // replacement scrolling code here:
                    if (e.wheelDelta > 0) this.scrollCount++
                    else this.scrollCount--
                    this.props.onScroll(this.scrollCount)
                    //set a new reset-wheel-handler function and fire the scroll-end function
                    this.wheelRestore = setTimeout(() => {
                        map.scrollZoom.onWheel = this.wheelHandler
                        this.scrollCount = 0
                    }, 500)
                } else map.scrollZoom.onWheel = this.wheelHandler //otherwise immediately reset it

            }
        }

    }

    /** Function when the zoom event ended
     * @param map
     */
    handleZoomEnd = (map) => {
        this.zoom = map.getZoom()
        if (this.props.onMove){
            this.props.onMove(map)
        }
    }

    onMapLoaded = (map) => {
        /** function called when map is loaded
         * return map **/
        if (this.props.onMapLoaded){
            this.props.onMapLoaded(map)
        }
    }

    handleDrag = (map) => {
        const lngLat = map.getCenter()
        this.latitude = lngLat.lat
        this.longitude = lngLat.lng
    }

    /** Function when the drag event ended
     * @param map
     */
    handleDragEnd = (map) => {
        const lngLat = map.getCenter()
        this.latitude = lngLat.lat
        this.longitude = lngLat.lng
        if (this.props.onMove){
            this.props.onMove(map)
        }
    }

    handleMoveStart = () => {
        //block the user interactions while the map is moving
        // this.map.container.style.pointerEvents = "none"
    }

    handleMoveEnd = () => {
        //unblock the user interactions 
        // this.map.container.style.pointerEvents = "auto"
    }

    render(){
        const {mapStyle, baseMap} = this.props
        let boundOption = {
          padding: 20
        }
        let mapbox = null
        if (this.center) {
            mapbox = <MapboxMap
                ref={el => this.map = el}
                style={baseMap}
                containerStyle={mapStyle}
                center={this.center}
                fitBounds={this.extent}
                fitBoundsOptions={boundOption}
                zoom={[this.zoom]}
                onClick={this.handleClick}
                onMouseMove={this.handleHover}
                onMoveStart={this.handleMoveStart}
                onMoveEnd={this.handleMoveEnd}
                onZoom={this.handleZoom}
                onZoomStart={this.handleZoomStart}
                onZoomEnd={this.handleZoomEnd}
                onDrag={this.handleDrag}
                onDragEnd={this.handleDragEnd}
                onDblClick={this.handleDoubleClick}
                onStyleLoad={this.onMapLoaded}
            >
                {this.props.children}
            </MapboxMap>
        }
        return mapbox
    }
}

Map.propTypes = {
    children: PropTypes.node, //the elements displayed on the map are provided as children
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    extent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        PropTypes.arrayOf(PropTypes.number)
    ]),
    zoom: PropTypes.number,
    onClick: PropTypes.func, //handler for map clicks
    onDoubleClick: PropTypes.func, //handler for double clicks
    onHover: PropTypes.func,  //handler for map hover events
    onScroll: PropTypes.func, //handler for map scroll events
    onDrag: PropTypes.func, //handler for map scroll events
    onMove: PropTypes.func, //handler for map move events
    mapStyle: PropTypes.shape({
        height: PropTypes.string,
        width: PropTypes.string
    }),
    baseMap: PropTypes.string
}


export default Map;