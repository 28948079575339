import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {bindActionCreators} from "redux";
import * as actions from "../actions";
import {deepCompare} from "../utils/objectUtils"

export class Router extends React.Component {
    constructor(props) {
        super(props)
        const {actions} = this.props
        if (props.match.params.countries) {
            props.match.params.countries.split(',').forEach((countryId, i) => {
                actions.selectCountry(countryId, (i > 0))
            });
        }
        if (props.match.params.layers) {
            props.match.params.layers.split(',').forEach(layerId => {
                actions.selectLayer(layerId)
            });
        }
    }

    render() {
        return ('')
    }

    componentDidUpdate(prevProps, prevState) {
        let changed = false;
        let url = ''
        let selectedCountry = Object.keys(this.props.countries.selectedIds);
        let prevSelectedCountry = Object.keys(prevProps.countries.selectedIds);
        if (!deepCompare(selectedCountry, prevSelectedCountry)) {
            changed = true;
        }

        let selectedLayer = Object.keys(this.props.layers.selectedIds);
        let prevSelectedLayer = Object.keys(prevProps.layers.selectedIds);
        if (!deepCompare(selectedLayer, prevSelectedLayer)) {
            changed = true;
        }

        // we create hash from center and zoom
        let hash = this.props.history.location.hash
        if (this.props.center &&
            this.props.center.lat && this.props.center.lng && this.props.zoom) {
            let newHash = '#' + [this.props.center.lat, this.props.center.lng, this.props.zoom].join('/')
            if (hash !== newHash) {
                changed = true
                hash = newHash
            }
        }
        if (changed) {
            if (!selectedCountry) {
                url += '/'
            } else {
                url += `/${selectedCountry}`
            }
            if (selectedLayer.length > 0) {
                url += `/${selectedLayer}`
            }
            this.props.history.push({
                hash: hash,
                pathname: url
            })
        }
    }
}

const mapStateToProps = state => ({
    system: state.system,
    countries: state.countries,
    layers: state.layers,
    center: state.map.center,
    zoom: state.map.zoom
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

Router.propTypes = {
    countries: PropTypes.shape({
        countriesById: PropTypes.object.isRequired,
        countryIds: PropTypes.array.isRequired,
        selectedIds: PropTypes.object.isRequired,
        lastSelectedId: PropTypes.string
    }),
    layers: PropTypes.shape({
        layersById: PropTypes.object.isRequired,
        layersByCountryId: PropTypes.object.isRequired,
        adminLevelsByCountryId: PropTypes.object.isRequired,
        layerIds: PropTypes.array.isRequired,
        selectedIds: PropTypes.object.isRequired
    }),
}
export default connect(mapStateToProps, mapDispatchToProps)(Router);