/**
 *  Purpose: the modifications that are carried on the Map object for each command
 */

import {initialState} from './index'
import * as types from '../actions/index'


const mapReducer = (state = initialState.map, action) => {
    const {type, payload} = action;
    switch (type){
        case types.SAVE_MAP: {
            return {
                ...state,
                mapbox: payload.mapbox
            }
        }
        case types.UPDATE_MAP_POSITION: {
            return {
                ...state,
                center: payload.center,
                zoom: payload.zoom
            }
        }

        default: return state
        
    }
}

export default mapReducer