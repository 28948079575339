import React from 'react'
import HomeMap from '../../screens/HomeMap'

import {
    Route, 
    Switch,
} from 'react-router-dom'

/**
 * A switch to serve pages to the user while they are authenticated
 */
class Main extends React.Component{
    render(){
        return (
            <Switch>
                <Route path="/:countries?" component={HomeMap} />
            </Switch>
        )
    }
}

export default Main;

