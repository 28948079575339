import * as Sentry from '@sentry/browser';
import {selectedEnvironment} from "../config"
import {PRODUCTION} from "../constants/environment"

export const logError = (error, errorInfo = null) => {  
    if (selectedEnvironment !== PRODUCTION) {
        console.warn(error)
    } else {
        if (typeof error === "string") error = new Error(error)
        Sentry.captureException(error, errorInfo);
    }
}
