import {logError} from './errorHandlingUtils'

export const stringBContainsStringA = (stringA, stringB) => {
    if (! (typeof stringA === 'string')) {
        logError(new Error(`stringBContainsStringA: stringA ${JSON.stringify(stringA)} is not a string`))
        return 
    }
    if (! (typeof stringB === 'string')) {
        logError(new Error(`stringBContainsStringA: stringA ${JSON.stringify(stringB)} is not a string`))
        return
    }
    stringA = stringA.toLowerCase().replace(/ +?|\.+/g, '')
    stringB = stringB.toLowerCase().replace(/ +?|\.+/g, '')
    return stringB.includes(stringA)
}

export const compareStrings = (stringA, stringB) => stringA.localeCompare(stringB, undefined, {sensitivity: 'base'})

const hashCode = (str) =>{ 
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
       hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
} 

const intToRGB =(i) => {
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

const subtractLight = function(color, amount){
    let cc = parseInt(color,16) - amount;
    let c = (cc < 0) ? 0 : (cc);
    c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
    return c;
  }

const darken = (color, amount) =>{
    color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
    amount = parseInt((255*amount)/100);
    return color = `#${subtractLight(color.substring(0,2), amount)}${subtractLight(color.substring(2,4), amount)}${subtractLight(color.substring(4,6), amount)}`;
    }

export const stringToColor = string => darken(`#${intToRGB(hashCode(string))}`, 15)

/**
 * Checks whether a string is a valid url
 * Source: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
 * @param {*} str 
 */
export const isValidURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }