export const WMS_DATA_SOURCE = "OGC:WMS"
export const TMS_DATA_SOURCE = "OSGeo:TMS"
export const GEOJSON_DATA_SOURCE = "OGC:OAPIF"
export const TILEJSON_DATA_SOURCE = "Mapbox:MVT"
export const HTML_LINK_SOURCE = "WWW:LINK"
export const GEOPACKAGE_DOWNLOAD_SOURCE = "download"

export const WMS_EXTENSION = 'map'
export const TMS_EXTENSION = 'png'
export const TILEJSON_EXTENSION = 'pbf'
export const GEOJSON_EXTENSION = 'json'
export const GEOPACKAGE_EXTENSION = 'zip'

export const dataSourceExtensionMap = {
    [WMS_DATA_SOURCE]: WMS_EXTENSION,
    [TMS_DATA_SOURCE]: TMS_EXTENSION,
    [TILEJSON_DATA_SOURCE]: TILEJSON_EXTENSION,
    [GEOJSON_DATA_SOURCE]: GEOJSON_EXTENSION,
    [GEOPACKAGE_DOWNLOAD_SOURCE]: GEOPACKAGE_EXTENSION
}

