import React from "react"
import PropTypes from "prop-types"
import CheckBox from '../../components/CheckBox'
import IconButton from '../../components/IconButton'
import MenuDropdown from '../../components/MenuDropdown'
import styles from "./LayerPicker.module.css"
import {downloadFile} from '../../utils/fileUtils'

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions";

import {compareStrings} from "../../utils/stringUtils"
export function LayerPicker({countries, layers, actions}){
    //if there are no selected countries, hide the component

    const selectedCountryIds = Object.keys(countries.selectedIds)
    let collapsedStyle = null
    if (selectedCountryIds.length === 0) collapsedStyle = styles.collapsed
    let availableLayerIds = [] 

    selectedCountryIds.forEach(countryId => {
        if (!layers.layersByCountryId[countryId]) return 
        else availableLayerIds = [...availableLayerIds, ...layers.layersByCountryId[countryId]]
    })
    const selectedCountry = selectedCountryIds.length === 1 ?
                countries.countriesById[selectedCountryIds[0]]
                :
                null

    const getLayerPickerOptions = layerIds => {
        layerIds.sort(( layerIdA, layerIdB) => {
            const layerA = layers.layersById[layerIdA]
            const layerB = layers.layersById[layerIdB]
            return compareStrings(layerA.title, layerB.title)
        })
        return layerIds.map(layerId =>{
            const layer = layers.layersById[layerId]
            return <LayerPickerOption 
                        key={layerId} 
                        layer={layer}
                        onSelect={() => actions.selectLayer(layerId)}
                        onDeselect={() => actions.deselectLayer(layerId)}
                        selected={Boolean(layers.selectedIds[layerId])}
                    />
        })
    }

    const getLayerCategories =layerIds =>{
        const layersByCategoryId = {}
        //loop through all available layers to find all the categories of the available layers
        layerIds.forEach(layerId => {
            const layer = layers.layersById[layerId]
            //if a new category has been found start the array of ids, otherwise add to it
            layersByCategoryId[layer.categoryId] = layersByCategoryId[layer.categoryId] ?
                                                    [...layersByCategoryId[layer.categoryId], layerId]:
                                                    [layerId]    
        })
        const categoryIdArray = Object.keys(layersByCategoryId)
        categoryIdArray.sort(( categoryIdA, categoryIdB) => {
            const categoryA = layers.layerCategoriesById[categoryIdA]
            if (categoryA) categoryIdA = categoryA.label
            const categoryB = layers.layerCategoriesById[categoryIdB]
            if (categoryB) categoryIdB = categoryB.label
            return compareStrings(categoryIdA, categoryIdB)
        })
        return categoryIdArray.map(categoryId => {
            const category = layers.layerCategoriesById[categoryId]
            const heading = category ? category.label : categoryId
            return <MenuDropdown
                        key={categoryId} 
                        heading={heading}
                        menuItems={getLayerPickerOptions(layersByCategoryId[categoryId])}
                    />
        })
    }

    const handleDownloadCountryLayers = () => {
        if (selectedCountryIds.length === 0) return null
        else if (selectedCountryIds.length === 1){ 
            const countryId = selectedCountryIds[0]
            const country = countries.countriesById[countryId]
            downloadFile(country.downloadSource, `${country.id}.gpkg`)
        }
    }
    const selectedLayerIds = Object.keys(layers.selectedIds)
    return (
        <div className={[styles.container, collapsedStyle, 'raised'].join(" ")}>
            <div className={styles.title}>Map Layers</div>
            <div className={styles.layerActions}>
                {
                    selectedCountryIds.length === 1 ?
                    <IconButton 
                        icon="cloud-download" 
                        onPress={handleDownloadCountryLayers}
                        tooltip={`download all public ${selectedCountry.name} layers`}
                    />
                    :
                    null
                }
            </div>
            {   
                selectedLayerIds.length > 0 ?
                <MenuDropdown
                    key={"selected-category"} 
                    heading="Selected"
                    menuItems={getLayerPickerOptions(selectedLayerIds)}
                    collapsed={false}
                />
                :
                null
            }
            {getLayerCategories(availableLayerIds)}
        </div>
    )
}

LayerPicker.propTypes = {
    actions: PropTypes.shape({
        selectLayer: PropTypes.func.isRequired,
        deselectLayer: PropTypes.func.isRequired
      }).isRequired,
    countries: PropTypes.shape({
        countriesById: PropTypes.object.isRequired,
        countryIds: PropTypes.array.isRequired,
        selectedIds: PropTypes.object.isRequired,
        lastSelectedId: PropTypes.string
    }).isRequired,
    layers: PropTypes.shape({
        layersById: PropTypes.object.isRequired,
        layersByCountryId: PropTypes.object.isRequired,
        layerCategoriesById:PropTypes.object.isRequired,
        selectedIds: PropTypes.object.isRequired
    }).isRequired,
}

class LayerPickerOption extends React.PureComponent{
    
    state={
        showDetails: false
    }

    static defaultProps = {
        onSelect: ()=>{},
        onDeselect: ()=>{},
        selected: false
    }

    toggleShowDetails = () => this.setState({showDetails: !this.state.showDetails})
    handleDownloadFile = () => {
        const {layer} = this.props
        downloadFile(layer.downloadSource, `${layer.id}.zip`)
    }
    render(){
        const {layer, selected, onSelect, onDeselect} = this.props
        const {showDetails} = this.state
        //TODO add sourced from field to the layer object during parsing
        return (
            <div className={styles.layerPickerOptionContainer}>
                <div className={styles.layerPickerInnerContainer}>
                    <CheckBox 
                        label={layer.title} 
                        checked={selected} 
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                    />
                    <IconButton icon="more-horiz" onPress={this.toggleShowDetails} tooltip="more"/>
                </div>
                {
                    showDetails ?
                    <div className={['moreDescription'].join(" ")}>
                        <div className={styles.layerActions}>
                            { layer.downloadSource ? <IconButton icon="cloud-download" onPress={this.handleDownloadFile} tooltip="download"/> : null}
                        </div>
                        <div className={styles.description}>
                            <div>type:</div><div>{layer.geometryType}</div>
                            <div>created:</div><div>{layer.createdAt}</div>
                            { layer.lastModifiedAt ? <React.Fragment><div>updated:</div><div>{layer.lastModifiedAt}</div></React.Fragment>: null}
                            { layer.sourcedFrom ? <React.Fragment><div>source:</div><div>{layer.sourcedFrom}</div></React.Fragment>: null}
                            <div>description:</div><div>{layer.description}</div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}
LayerPickerOption.propTypes = {
    layer: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func
}
const mapStateToProps = state => ({
    countries: state.countries,
    layers: state.layers
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  });


export default connect(mapStateToProps, mapDispatchToProps)(LayerPicker);