import React from 'react'
import CloseButton from '../CloseButton'
import Icon from '../Icon'

import styles from './SearchField.module.css'
import PropTypes from 'prop-types'

class SearchField extends React.Component {

    static defaultProps = {
        placeholder: 'Search',
        onChangeText: () => {},
        onChangeFocus: () => {},
        onPressClose: () => {}
    }

    constructor(props){
        super(props)
        this.state = {
            text: props.text ? props.text: "",
            hasFocus: false
        }
    }

    static getDerivedStateFromProps(props, state){
        if (props.text !== state.text) return {text: props.text}
        else return null
    }

    handleChangeText = text => {
        this.setState({text}, ()=>this.props.onChangeText(text))
    } 

    handleChangeFocus = hasFocus => {
        this.setState({hasFocus}, this.props.onChangeFocus(hasFocus))
    }

    handlePressClose = () => {
        const {onPressClose} = this.props
        onPressClose()
        this.handleChangeText("")
    }

    render(){
        const {text} = this.state
        const {placeholder} = this.props
        return (
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <Icon icon={'search'}/>
                    <input 
                        value={text} 
                        type='text' 
                        onChange={event => this.handleChangeText(String(event.target.value))}
                        onFocus={() => this.handleChangeFocus(true)}
                        onBlur={() => this.handleChangeFocus(false)}
                        placeholder={placeholder}
                    />
                    <CloseButton onPress={this.handlePressClose}/>
                </div>
            </div>
        )
    }
}

SearchField.propTypes = {
    text: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeText: PropTypes.func,
    onChangeFocus: PropTypes.func,
    onPressClose: PropTypes.func
}

export default SearchField;